import { CRS } from 'leaflet';
import wkx from 'wkx';
import { LayerData, CrossSection } from '../types';
import { guppy } from '../services/guppy.service';
import { Drawing } from '../components/draw-control';

export async function getCrossSectionData(
  layers: LayerData[],
  drawing: Drawing
): Promise<CrossSection[]> {
  const wkt = parseDrawingToWKT(drawing);

  const dataPromises = layers.map(
    async (layer): Promise<CrossSection> => {
      const rasterData: number[] = await guppy.getRasterLinedata(
        layer.guppyLayer!.uuid,
        {
          srs: CRS.EPSG4326.code!,
          geometry: wkt,
          resolution: 'auto',
          datapoints: 400,
        }
      );

      return {
        layer,
        // TODO: this is not a correct dataset to visualise
        data: rasterData,
      };
    }
  );

  const data = await Promise.all(dataPromises);
  return data;
}

function parseDrawingToWKT(drawing: Drawing): string {
  const wkt = wkx.Geometry.parseGeoJSON(drawing.layer!.geometry).toWkt();
  return wkt;
}
