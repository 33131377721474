import React, { useMemo } from 'react';
import classnames from 'classnames';
import Plot from 'react-plotly.js';
import { PlotData } from 'plotly.js';
import { useTranslation } from 'react-i18next';

import { CrossSection } from '../../types';
import Icon from './../icon';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  closeGraph: () => void;
  crossSections: CrossSection[];
}

export const CrossSectionComponent = (props: Props) => {
  const { crossSections } = props;
  const { t } = useTranslation();

  const traces = useMemo(() => {
    // define a trace per crossSection
    const traces = crossSections.map((crossSection, i) => {
      const { data } = crossSection;

      const plotData: Partial<PlotData> = {
        x: data.map((value, index) => index),
        y: data.map(value => value),
        type: 'scatter',
        mode: 'lines',
        hovertemplate: '%{y}',
        name: '',
      };

      return plotData;
    });

    return traces;
  }, [crossSections]);

  // render the component
  return render();

  function render() {
    return (
      <div className={classnames(props.className, styles.container)}>
        <div className={styles.header}>
          <h1 className={styles.title}>{t('crossSection.title')}</h1>
          <span className={styles.closeButton}>
            <Icon
              name="times"
              size={20}
              fill="white"
              onClick={props.closeGraph}
            />
          </span>
        </div>
        <div className={styles.body}>{renderPlot()}</div>
      </div>
    );
  }

  function renderPlot() {
    return (
      <Plot
        style={{ width: '100%', height: '100%', background: 'transparent' }}
        data={traces}
        layout={{
          autosize: true,
          plot_bgcolor: '#1e2c4b',
          paper_bgcolor: '#1e2c4b',
          legend: { orientation: 'h', y: -0.25 },
          hovermode: 'closest',

          xaxis: {
            color: 'white',
            showgrid: false,
            linecolor: 'white',
            linewidth: 1,
            showticklabels: false,
          },
          yaxis: {
            color: 'white',
            hoverformat: '.2f',
            ticksuffix: ' µg/m³',
            linecolor: 'white',
            linewidth: 1,
          },
          margin: {
            t: 20,
            b: 40,
            l: 80,
            r: 40,
          },
        }}
      />
    );
  }
};
