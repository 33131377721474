import axios from 'axios';
import { WMSTileLayerProps } from 'react-leaflet';
import { MapEventData } from '../utils/leaflet.utils';
import { FeatureCollection } from 'geojson';

export async function getFeatureInfo(
  mapEventData: MapEventData,
  layer: WMSTileLayerProps
) {
  const config = {
    params: {
      version: '1.1.0',
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: 'EPSG:4326',
      layers: layer.layers,
      query_layers: layer.layers,
      x: mapEventData.x,
      y: mapEventData.y,
      bbox: mapEventData.bbox,
      width: mapEventData.size.x,
      height: mapEventData.size.y,
      info_format: 'application/json',
    },
  };

  const response = await axios.get<FeatureCollection>(layer.url, config);
  return response.data;
}
