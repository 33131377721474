import axios from 'axios';
import { LayerData, GuppyLayer } from '../types';
import { sortBy } from 'lodash-es';

interface MapResponseItem {
  id: number;
  modelId: number;
  metricId: number;
  mapDate: number;
  wmsLayer?: {
    category: string;
    layer: string;
    url: string;
  };
  guppyLayer: GuppyLayer;
}

interface IMapsResponse {
  data: MapResponseItem[];
}

export async function getNRTMaps(metricId: number): Promise<LayerData[]> {
  try {
    const response = await axios.get<IMapsResponse>(`/api/nrt/maps`, {
      params: {
        metricId,
      },
    });
    const { data } = response.data;

    // map response data to Layer interface
    const layers = data.map((item) => {
      // an item is possibly null, then we only know of the date
      if (!item.wmsLayer) {
        return {
          ...item,
          wmsLayer: null,
        };
      }

      return parseResponseItemToLayerItem(item);
    });

    return sortBy(layers, ['mapDate']);
  } catch (ex) {
    console.error('[getNRTMaps] execption thrown:', ex);
    throw ex.response;
  }
}

export async function getHistoricalMaps(): Promise<LayerData[]> {
  try {
    const response = await axios.get<IMapsResponse>(`/api/historical/maps`, {
      params: {},
    });
    const { data } = response.data;
    // TODO: should this be gone when the api is more data solid?
    const filteredData = data.filter(
      (item: MapResponseItem) => item.id !== null
    );

    // map response data to Layer interface
    const layers = filteredData.map((item: MapResponseItem) => {
      return parseResponseItemToLayerItem(item);
    });

    return layers;
  } catch (ex) {
    console.error('[getHistoricalMaps] execption thrown:', ex);
    throw ex.response;
  }
}

export async function getForecastMaps(): Promise<LayerData[]> {
  const url = `/api/forecast/maps`;
  const response = await axios.get<IMapsResponse>(url);
  const { data } = response.data;

  const layers = data.map(parseResponseItemToLayerItem);
  return layers;
}

export async function getNrtMapsForTimestamp(
  timestamp: number
): Promise<LayerData[]> {
  const response = await axios.get<IMapsResponse>(`/api/nrt/maps-for-time`, {
    params: { timestamp },
  });

  const { data } = response.data;
  return data.map(parseResponseItemToLayerItem);
}

function parseResponseItemToLayerItem(item: MapResponseItem): LayerData {
  return {
    id: item.id,
    modelId: item.modelId,
    metricId: item.metricId,
    mapDate: item.mapDate,
    layerName: item.wmsLayer?.layer || '',
    wmsLayer: item.wmsLayer
      ? {
          url: item.wmsLayer.url,
          layers: `${item.wmsLayer.category}:${item.wmsLayer.layer}`,
          opacity: 0.7,
          format: 'image/png',
          transparent: true,
        }
      : null,
    guppyLayer: item.guppyLayer ? item.guppyLayer : null,
  };
}
