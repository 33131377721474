import { SET_AUTH_TOKENS, AuthState, AuthActionTypes } from './types';

export const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case SET_AUTH_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    default:
      return state;
  }
};

export default authReducer;
