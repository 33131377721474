import React, { useMemo } from 'react';
import { round, uniqBy } from 'lodash-es';

import { DashboardOverviewData } from '../services/dashboard.service';
import { ModelCell } from '../components/model-cell';
import { Table, TableRow, TableHead, TableCell } from '../components/table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  data: DashboardOverviewData[];
}

export const DashboardOverviewTable = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  const models = useMemo(() => {
    return uniqBy(data, 'modelId').map(item => ({
      id: item.modelId,
      name: item.modelName,
      color: item.modelColor,
    }));
  }, [data]);

  const horizons = useMemo(() => {
    return uniqBy(data, 'horizon').map(item => item.horizon);
  }, [data]);

  // render the component
  return render();

  function render() {
    return (
      <Table>
        <thead>
          <TableRow>
            <TableHead>{t('dashboard.overview.table.model')}</TableHead>
            {horizons.map((horizon, i) => (
              <TableHead key={i}>
                {t('dashboard.overview.dayPlus', { horizon })}
              </TableHead>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {models.map(model => (
            <TableRow key={model.id}>
              <TableCellModel>
                <ModelCell color={model.color}>{model.name}</ModelCell>
              </TableCellModel>

              {horizons.map(horizon => {
                const cellItem = data.find(
                  item => item.horizon === horizon && item.modelId === model.id
                );
                if (!cellItem) return '-';
                return (
                  <TableCell key={cellItem.id}>
                    {round(cellItem.value, 2)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  }
};
const TableCellModel = styled(TableCell)`
  padding-left: 0;
`;
