import { Dispatch } from 'redux';
import { RootState } from '../../types';

// load initial application data
export function load() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };
}
