import React from 'react';
import { RouteProps, RouteComponentProps, Redirect, Route } from 'react-router';

interface Props extends RouteProps {
  isAuthenticated: boolean;
}

export const PrivateRoute = ({ component, ...rest }: Props) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (rest.isAuthenticated) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={render} />;
};
