import React, { useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useBoolean } from 'react-hanger';
import classnames from 'classnames';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Icon from './../../components/icon';
import { languages } from '../../config';
import styles from './styles.module.scss';

interface OwnProps {}
type Props = OwnProps;

export const HeaderContainer = (props: Props) => {
  const isProfileOpen = useBoolean(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const defineSectionTitle = useCallback(
    (pathname: string) => {
      switch (pathname) {
        case '/nrt/maps':
          return t('header.title.nrtMaps');
        case '/nrt/animation':
          return t('header.title.nrtAnimation');
        case '/forecast/maps':
          return t('header.title.forecastMaps');
        case '/forecast/dashboard/overview':
          return t('header.title.forecastDashboardOverview');
        case '/forecast/dashboard/validation':
          return t('header.title.forecastDashboardValidation');
        case '/historical/maps':
          return t('header.title.historicMaps');
        default:
          return '...';
      }
    },
    [t]
  );

  const title = useMemo(() => {
    return defineSectionTitle(location.pathname);
  }, [defineSectionTitle, location.pathname]);

  function setLanguage(key: string) {
    i18n.changeLanguage(key);
  }

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>

        <div className={styles.spacer}></div>

        <div className={styles.languageSelect}>
          {languages.map((language, i) => (
            <div
              key={language.key}
              className={classnames(styles.languageItem, {
                [styles.active]: i18n.language === language.key,
              })}
              onClick={() => setLanguage(language.key)}
            >
              {language.displayName}
            </div>
          ))}
        </div>

        {/* <div className={styles.profileItem}>
          <div
            className={styles.profileItemVisible}
            onClick={isProfileOpen.toggle}
          >
            Bino Maiheu
            <Icon
              name="caret-down"
              size={14}
              fill="#3fabff"
              className={styles.caret}
            />
          </div>

          {isProfileOpen.value && (
            <div className={styles.profileItemCollapsed}>
              <NavLink to="/profile" className={styles.subNavItem}>
                {t('navigation.menu.profile')}
              </NavLink>
              <NavLink to="/logout" className={styles.subNavItem}>
                {t('navigation.menu.logout')}
              </NavLink>
            </div>
          )}
        </div> */}
      </div>
    );
  }
};
