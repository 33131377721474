import { UnknownConfigLanguage } from '../errors';

interface Language {
  key: string;
  displayName: string;
}

const ALL_LANGUAGES: Language[] = [
  {
    key: 'en',
    displayName: 'English',
  },
  {
    key: 'hr',
    displayName: 'Hrvatski',
  },
  {
    key: 'sk',
    displayName: 'Slovák',
  },
];

// Construct languages array based on env config
export const languages = (window._env_.REACT_APP_LANGUAGES?.split(
  ','
) as string[]).map((languageKey) => {
  const language = ALL_LANGUAGES.find(
    (language) => language.key === languageKey
  );
  if (!language) throw UnknownConfigLanguage(languageKey);
  return language;
});
