import axios from 'axios';
import { auth } from '../services/auth.service';
import history from '../history';

// I18n interception
export function i18nInterceptor(language: string) {
  // i18n interceptor
  axios.interceptors.request.use(config => {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Accept-Language': language,
      },
    };
  });
}

// Auth interception
// Request interceptor
axios.interceptors.request.use(async config => {
  return await auth.requestInterceptor(config);
});

// Response interceptor
axios.interceptors.response.use(
  function responseOK(value: any): any {
    return value;
  },
  function responseNOK(error) {
    return auth.responseErrorHandler(error, () => {
      history.push('/login');
    });
  }
);
