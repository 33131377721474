import axios from 'axios';
import { getUnixTime } from 'date-fns/esm';

export interface DashboardOverviewData {
  id: number;
  stationId: number;
  metricId: number;
  unit: string;
  missingValue: number;
  quantityName: string;
  aggregationKey: string;
  modelId: number;
  modelName: string;
  modelColor: string;
  baseTime: number;
  fctimeStart: number;
  fctimeEnd: number;
  horizon: number;
  value: number;
}

export async function getDashboardOverviewData(
  stationId: number,
  metricId: number,
  baseTime: Date
): Promise<DashboardOverviewData[]> {
  const response = await axios.get(`/api/dashboard/overview`, {
    params: { metricId, stationId, baseTime: getUnixTime(baseTime) },
  });

  return response.data.data;
}

export async function getDashboardDates(
  stationId: number,
  metricId: number,
  year: number,
  horizon = 0
): Promise<Date[]> {
  try {
    const response = await axios.get(`/api/dashboard/overview/dates`, {
      params: { year, stationId, metricId, horizon },
    });
    return response.data.data.map((date: number) => new Date(date * 1000));
  } catch (error) {
    throw error;
  }
}

export interface DashboardValidationData {
  values: Array<{
    id: number;
    stationId: number;
    metricId: number;
    unit: string;
    missingValue: number;
    quantityName: string;
    aggregationKey: string;
    modelId: number;
    modelName: string;
    modelColor: string;
    baseTime: number;
    fctimeStart: number;
    fctimeEnd: number;
    horizon: number;
    value: number;
  }>;
  statistics: Array<{
    modelId: number;
    color: string;
    values: Array<{
      key: string;
      value: number;
    }>;
  }>;
  scatterData: [
    {
      modelId: number;
      values: [number, number];
    }
  ];
}

export async function getDashboardValidationData(
  stationId: number,
  metricId: number,
  horizon: number,
  dateRange: { from: Date; to: Date }
): Promise<DashboardValidationData> {
  const response = await axios.get(`/api/dashboard/validation`, {
    params: {
      metricId,
      stationId,
      horizon,
      fromDate: getUnixTime(dateRange.from),
      tillDate: getUnixTime(dateRange.to),
    },
  });

  return response.data.data;
}

export async function getDashboardForecastHorizon(
  stationId: number,
  metricId: number
): Promise<Array<number>> {
  const response = await axios.get(`/api/dashboard/validation/horizon`, {
    params: {
      metricId,
      stationId,
    },
  });

  return response.data.data;
}
