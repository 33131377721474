import { Metric } from '../../types';

export interface BasedataState {
  metrics: Metric[];
}

export const SET_METRICS = 'SET_METRICS';

interface SetMetricsAction {
  type: typeof SET_METRICS;
  payload: Metric[];
}

export type BasedataActionTypes = SetMetricsAction;
