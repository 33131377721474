import { LocaleUtils } from 'react-day-picker';

const WEEKDAYS_LONG: { [locale: string]: string[] } = {
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  hr: [
    'Nedjelja',
    'Ponedjeljak',
    'Utorak',
    'Srijeda',
    'Četvrtak',
    'Petak',
    'Subota',
  ],
};

const WEEKDAYS_SHORT: { [locale: string]: string[] } = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  hr: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
};

const MONTHS: { [locale: string]: string[] } = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  hr: [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac',
  ],
};

const FIRST_DAY: { [locale: string]: number } = {
  en: 0,
  hr: 0, // Use Monday as first day of the week
};

function formatDay(d: Date, locale = 'en') {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
    MONTHS[locale][d.getMonth()]
  } ${d.getFullYear()}`;
}

function formatMonthTitle(d: Date, locale = 'en') {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i: number, locale: string) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i: number, locale: string) {
  return WEEKDAYS_SHORT[locale][i];
}

function getFirstDayOfWeek(locale: string) {
  return FIRST_DAY[locale];
}

export const localeUtils = {
  ...LocaleUtils,
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
};
