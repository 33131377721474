import React from 'react';
import { round } from 'lodash-es';
import color from 'color';

import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
  value: number | null;
  unit: string;
  quantityName: string;
  colorInterpolator: (value: number) => string;
}

export const MetricValueItem = (props: Props) => {
  const { quantityName, unit, value, isLoading } = props;

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.pollutant}>
          {quantityName}
          <span className={styles.unit}>{unit}</span>
        </div>

        {isLoading && renderLoading()}
        {!isLoading && value && renderValue()}
        {!isLoading && !value && renderNullValue()}
      </div>
    );
  }

  function renderLoading() {
    return <div className={styles.value}>...</div>;
  }

  function renderValue() {
    if (!value) return null;
    const valueColor = props.colorInterpolator(value);

    return (
      <div
        className={styles.value}
        style={{
          backgroundColor: valueColor,
          color: color(valueColor).isDark() ? 'white' : 'black',
        }}
      >
        {round(value, 1).toFixed(1)}
      </div>
    );
  }

  function renderNullValue() {
    return <div className={styles.value}>-</div>;
  }
};

MetricValueItem.defaultProps = {
  isLoading: false,
};
