import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TileLayer, WMSTileLayer } from 'react-leaflet';
import { LeafletMouseEvent } from 'leaflet';
import Select from 'react-select';
import {
  Map,
  MapControlGroup,
  MapControl,
  MapControlButton,
} from 'marvin-ui-kit';
import { useBoolean } from 'react-hanger';
import { isSameDay } from 'date-fns/esm';
import { isEmpty, pick, find } from 'lodash-es';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import {
  getBaselayers,
  getForecastMapsSelection,
  getLegendMinMaxSelection,
} from '../../selectors';
import { getForecastMaps } from '../../services/map.service';
import { LayerData, Metric, Station, APILegend } from '../../types';
import { getForecastMetrics } from '../../services/metric.service';
import { formatMetricName } from '../../utils/format.utils';
import { formatDate } from '../../utils/format.utils';
import { getSelectValue } from '../../utils/form.utils';
import { RadioList, RadioListItem } from '../../components/radio-button-list';
import { setForecastMapsFormSelection } from '../../redux/selection/actions';
import {
  SelectionGroup,
  SelectionGroupItem,
  SelectionGroupLabel,
} from '../../components/selection-group';
import Icon from '../../components/icon';
import { useMapPosition } from '../../hooks';
import { getForecastStations } from '../../services/station.service';
import { StationMarker } from '../../components/station-marker';
import {
  RasterCellPopup,
  MetricRaster,
} from '../../components/raster-cell-popup';
import { MapEventData, getLeafletEventData } from '../../utils/leaflet.utils';
import { LegendContainer } from '../legend.container';
import { getLegend, getLegendSldUrl } from '../../services/legend.service';
import { ForecastsChart } from '../../components/forecasts-chart';
import styles from './styles.module.scss';

interface Props {}

export const ForecastMapsContainer = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formData = useSelector(getForecastMapsSelection);

  const { position, setMapPosition, setMapZoom } = useMapPosition();
  const baselayers = useSelector(getBaselayers);

  const legendMinMaxSelection = useSelector(getLegendMinMaxSelection);
  const selection = useSelector(getForecastMapsSelection);
  const activeLayer = selection.layer;

  const [legend, setLegend] = useState<APILegend | null>(null);
  const [layers, setLayers] = useState<LayerData[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [stations, setStations] = useState<Station[]>([]);
  const [rasterCellData, setRasterCellData] = useState<MapEventData | null>(
    null
  );
  const [activeStation, setActiveStation] = useState<Station | null>(null);
  const showChart = useBoolean(false);

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );
  const legendMinMax = useMemo(() => {
    if (legendMinMaxSelection) return legendMinMaxSelection;
    if (activeMetric) return pick(activeMetric, ['min', 'max']);

    // return default legend
    console.warn('Can not define legend min/max, fallback to default.');
    return { min: 0, max: 50 };
  }, [legendMinMaxSelection, activeMetric]);

  // all layers matching the selected date
  const metricRasterValues: MetricRaster[] = useMemo(() => {
    if (!activeLayer) return [];

    return layers.reduce(
      (metricRastersAcc: MetricRaster[], layer: LayerData) => {
        const metric = find(metrics, { id: layer.metricId });

        if (!metric) return metricRastersAcc;
        if (!layer.wmsLayer) return metricRastersAcc;
        if (layer.mapDate !== activeLayer.mapDate) return metricRastersAcc;

        return [
          ...metricRastersAcc,
          {
            metric,
            wmsLayer: layer.wmsLayer,
          },
        ];
      },
      []
    );
  }, [metrics, layers, activeLayer]);

  // compute layers aviallable for selected metric
  const availlableLayers = useMemo(() => {
    if (!activeMetric) return [];
    return layers.filter((layer) => layer.metricId === activeMetric.id);
  }, [layers, activeMetric]);

  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setLayers(initialData.layers);
      setMetrics(initialData.metrics);
      setStations(initialData.stations);

      handleMetricSelect(initialData.metrics[0]);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(ensureActiveLayerForMetric, [
    activeMetric,
    activeLayer,
    availlableLayers,
  ]);

  // update legend based on active layer and min/max selection
  useEffect(() => {
    if (!activeLayer?.layerName) return;

    const { min, max } = legendMinMax;
    getLegend(activeLayer.layerName, min, max).then((legend) => {
      setLegend(legend);
    });
  }, [activeLayer, legendMinMax]);

  function ensureActiveLayerForMetric() {
    // if the active layer does not match the active metric, set a new active layer
    if (activeLayer && activeLayer?.metricId !== activeMetric?.id) {
      const match = availlableLayers.find((layer) =>
        isSameDay(layer.mapDate * 1000, activeLayer.mapDate * 1000)
      );

      if (match) {
        dispatch(setForecastMapsFormSelection({ ...selection, layer: match }));
      }
    }
  }

  function handleShowTimeseries(station: Station) {
    setActiveStation(station);
    showChart.setTrue();
  }

  function handleMapClick(event: LeafletMouseEvent) {
    const mapEventData = getLeafletEventData(event);
    setRasterCellData(mapEventData);
  }

  function handleMetricSelect(metric: Metric) {
    dispatch(
      setForecastMapsFormSelection({
        ...selection,
        metricId: metric.id,
      })
    );
  }

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.mapContainer}>
        <Map
          zoom={position.zoom}
          lat={position.lat}
          lng={position.lng}
          onChange={(position) => setMapPosition(position)}
          onClick={handleMapClick}
        >
          {baselayers.map((layer, i) => (
            <TileLayer key={layer.key} url={layer.url} attribution="" />
          ))}
          {activeLayer && legendMinMax && (
            <WMSTileLayer
              {...activeLayer.wmsLayer!}
              sld={getLegendSldUrl(legendMinMax, activeLayer)}
            />
          )}

          {renderStations()}
          {renderRasterCellPopup()}
        </Map>

        <MapControlGroup position="TOP_LEFT">
          <MapControl>
            <SelectionGroup>
              {renderMetricSelection()}
              {renderForecastSelection()}
            </SelectionGroup>
          </MapControl>
        </MapControlGroup>

        <MapControlGroup
          position="BOTTOM_LEFT"
          css={css`
            width: 100%;
            align-items: flex-start;
          `}
        >
          <div className={styles.mapControlGroupRow}>
            {renderLegend()}
            {renderForecastsChart()}
          </div>
        </MapControlGroup>

        <MapControlGroup position="BOTTOM_RIGHT">
          <MapControlButton onClick={() => setMapZoom('in')}>
            <Icon name="plus" />
          </MapControlButton>
          <MapControlButton onClick={() => setMapZoom('out')}>
            <Icon name="minus" />
          </MapControlButton>
        </MapControlGroup>
      </div>
    );
  }

  function renderMetricSelection() {
    const options = metrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      const metric = find(metrics, { id: metricId });
      if (metric) handleMetricSelect(metric);
    }
    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('metric')}</SelectionGroupLabel>
        <Select
          className="select"
          value={find(options, { value: activeMetric?.id })}
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </SelectionGroupItem>
    );
  }

  function renderForecastSelection() {
    if (!activeMetric) return null;

    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('forecast')}</SelectionGroupLabel>

        <RadioList className={styles.availlableLayersList}>
          {isEmpty(availlableLayers) && t('forecastEmptyState')}

          {availlableLayers.map((layer, i) => (
            <RadioListItem
              key={i}
              className={styles.radioListItem}
              active={activeLayer ? activeLayer.id === layer.id : false}
              onClick={() =>
                dispatch(setForecastMapsFormSelection({ ...selection, layer }))
              }
            >
              {formatDate(layer.mapDate * 1000, 'dd/MM/y')}
            </RadioListItem>
          ))}
        </RadioList>
      </SelectionGroupItem>
    );
  }

  function renderLegend() {
    if (!activeMetric) return null;

    return (
      <LegendContainer
        customMinMax={legendMinMaxSelection}
        metric={activeMetric}
        layer={activeLayer}
        bounds={null}
        legend={legend}
      />
    );
  }
  function renderStations() {
    if (isEmpty(stations)) return null;
    if (!activeMetric) return null;
    if (!activeLayer) return null;
    if (!legend) return null;

    return stations.map((station) => {
      return (
        <StationMarker
          key={station.id}
          station={station}
          legend={legend}
          activeTime={activeLayer.mapDate}
          activeMetric={activeMetric}
          onShowTimeseries={() => handleShowTimeseries(station)}
        />
      );
    });
  }

  function renderForecastsChart() {
    if (!showChart.value) return null;
    if (!activeStation) return null;

    function close() {
      showChart.setFalse();
    }

    return (
      <MapControl className={styles.timeseriesChartContainer}>
        <ForecastsChart station={activeStation} onClose={close} />
      </MapControl>
    );
  }

  function renderRasterCellPopup() {
    if (!rasterCellData) return null;
    if (!legend) return null;
    const { latlng } = rasterCellData;

    return (
      <RasterCellPopup
        key={latlng.toString()}
        metricRasters={metricRasterValues}
        mapEventData={rasterCellData}
        legend={legend}
      />
    );
  }
};

interface InitialData {
  layers: LayerData[];
  metrics: Metric[];
  stations: Station[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const layers = await getForecastMaps();
  const metrics = await getForecastMetrics();
  const stations = await getForecastStations();
  return { layers, metrics, stations };
}
