import React, { useState, useMemo } from 'react';
import { find } from 'lodash-es';
import { getYear } from 'date-fns/esm';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Metric, LayerData, Model } from '../types';
import { formatMetricName } from '../utils/format.utils';
import { SelectionGroupItem, SelectionGroupLabel } from './selection-group';
import { Button } from './button';
import { getSelectValue } from '../utils/form.utils';

interface Props {
  // selected state
  selectionState: {
    model: Model | null;
    metricId: number | null;
    year: number | null;
  };
  models: Model[];
  layers: LayerData[];
  metrics: Metric[];
  onSubmit: (
    selectedModel: Model,
    selectedMetricId: number,
    selectedYear: number
  ) => void;
}

export const LayerSelection = (props: Props) => {
  const { t } = useTranslation();

  const [selectedModel, setSelectedModel] = useState<Model | null>(
    props.selectionState.model
  );
  const [selectedMetricId, setSelectedMetricId] = useState<number | null>(
    props.selectionState.metricId
  );
  const [selectedYear, setSelectedYear] = useState<number | null>(
    props.selectionState.year
  );

  const { models, metrics, layers } = props;

  const availableModels = models;

  // compute metrics based on all layers
  const availableMetrics: Metric[] = useMemo(() => {
    if (selectedModel === null) return [];

    return layers.reduce((allMetricsAcc: Metric[], layer) => {
      // check if layer model matches selected model
      if (layer.modelId !== selectedModel.id) return allMetricsAcc;

      // if yes, add layer year to list
      const metric = find(metrics, (metric) => metric.id === layer.metricId);

      if (metric === undefined) return allMetricsAcc;
      if (allMetricsAcc.includes(metric)) return allMetricsAcc;
      return [...allMetricsAcc, metric];
    }, []);
  }, [selectedModel, layers, metrics]);

  // compute years based on the selected metric and all layers
  const availableYears = useMemo(() => {
    if (selectedModel === null) return [];
    if (selectedMetricId === null) return [];

    return layers.reduce((allYearsAcc: number[], layer) => {
      // check if layer metric matches selected metric
      if (
        layer.metricId === selectedMetricId &&
        layer.modelId === selectedModel?.id
      ) {
        const year = getYear(layer.mapDate * 1000);
        if (allYearsAcc.includes(year)) return allYearsAcc;
        return [...allYearsAcc, year].sort();
      } else {
        return allYearsAcc;
      }
    }, []);
  }, [selectedModel, selectedMetricId, layers]);

  /**
   * Functions
   */
  function handleSubmit() {
    if (
      selectedModel !== null &&
      selectedMetricId !== null &&
      selectedYear !== null
    ) {
      props.onSubmit(selectedModel, selectedMetricId, selectedYear);
    }
  }

  // render the component
  return render();

  function renderModelSelection() {
    const options = availableModels.map((model) => ({
      value: model.id,
      label: model.name,
    }));

    function handleChange(modelId: number) {
      const model = find(availableModels, { id: modelId });
      if (model) {
        setSelectedModel(model);
        setSelectedMetricId(null);
        setSelectedYear(null);
      }
    }
    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('model')}</SelectionGroupLabel>
        <Select
          className="select"
          value={find(options, { value: selectedModel?.id })}
          placeholder={t('modelPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </SelectionGroupItem>
    );
  }

  function renderMetricSelection() {
    const options = availableMetrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      if (metricId) {
        setSelectedMetricId(metricId);
        setSelectedYear(null);
      }
    }

    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('metric')}</SelectionGroupLabel>
        <Select
          className="select"
          value={
            options.find((option) => option.value === selectedMetricId) || null
          }
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </SelectionGroupItem>
    );
  }

  function renderYearSelection() {
    const options = availableYears.map((year) => ({
      value: year,
      label: year.toString(),
    }));

    function handleChange(year: number) {
      if (year) setSelectedYear(year);
    }

    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('year')}</SelectionGroupLabel>
        <Select
          className="select"
          value={selectedYear ? find(options, { value: selectedYear }) : null}
          placeholder={t('yearPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </SelectionGroupItem>
    );
  }

  function render() {
    return (
      <div>
        {renderModelSelection()}
        {renderMetricSelection()}
        {renderYearSelection()}

        <SelectionGroupItem>
          <Button
            disabled={selectedMetricId === null || selectedYear === null}
            onClick={handleSubmit}
          >
            {t('actions.load')}
          </Button>
        </SelectionGroupItem>
      </div>
    );
  }
};
