import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { em } from 'polished';
import variables from './../styles/core/variables.scss';

export const SubNavigation = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  background-color: #f2f2f2;
`;

export const SubNavigationItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  color: #212121;
  font-family: ${variables.fontFamilyAvenirLight};
  font-size: ${em(14, variables.baseFontSize)};
  text-decoration: none;

  & + & {
    margin-left: 30px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: transparent;
    bottom: 0;
    transition: all 300ms;
  }

  &.active {
    &:before,
    &:hover &:before {
      opacity: 1;
      background-color: #3fabff;
    }
    &:hover {
      &:before {
        opacity: 0.5;
        background-color: #3fabff;
      }
    }
  }
`;
