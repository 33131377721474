import React from 'react';
import { noop } from 'lodash-es';
import { useSpring, animated } from 'react-spring';
import cx from 'classnames';
import { useBoolean } from 'react-hanger';
import { useTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { APILegend } from '../../types';
import Icon from './../icon';
import { Tooltip } from 'marvin-ui-kit';
import { InvalidFeedback } from '../form';
import styles from './styles.module.scss';

interface Props {
  legend: APILegend;
  boundaries: { min: number; max: number };
  title: string;
  entity: string;
  dynamicCustomize: boolean;
  onCustomizeWithBoundary: (boundary: { min: number; max: number }) => void;
  onCustomizeDynamic: () => void;
  onReset: () => void;
  onClose: () => void;
}

const LegendSchema = Yup.object().shape({
  min: Yup.number().required('Min value is required'),
  max: Yup.number().max(999).required('Max value is required'),
});

const LegendDynamic = (props: Props) => {
  const { t } = useTranslation();
  const isFlipped = useBoolean(false);

  const { transform, opacity } = useSpring({
    opacity: isFlipped.value ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped.value ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  // destructure props
  const { title, entity, legend } = props;

  return render();

  function render() {
    return (
      <div className={styles.container}>
        {renderFront()}
        {renderBack()}
      </div>
    );
  }

  function renderFront() {
    return (
      <animated.div
        className={cx(styles.front, { [styles.isActive]: !isFlipped.value })}
        style={{
          opacity: opacity.interpolate((o) => 1 - o),
          transform,
        }}
      >
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.entity}>{entity}</div>
            <div className={styles.close} onClick={props.onClose}>
              <Icon name="times" size={15} />
            </div>
          </div>
          <div className="scale">
            {legend.colorMap.entries.map((item, i) => (
              <ScaleItem key={i} data={item}></ScaleItem>
            ))}
          </div>
        </div>
        <div className={styles.actionsContainer}>
          <Tooltip text={t('legend.actions.edit')} position="top">
            <div className={styles.action} onClick={isFlipped.toggle}>
              <Icon name="edit" size={16} />
            </div>
          </Tooltip>

          {props.dynamicCustomize && (
            <Tooltip text={t('legend.actions.customizeDynamic')} position="top">
              <div className={styles.action} onClick={props.onCustomizeDynamic}>
                <Icon name="paint-brush" size={15} />
              </div>
            </Tooltip>
          )}

          {!props.dynamicCustomize && (
            <Tooltip text={t('legend.actions.reset')} position="top">
              <div className={styles.action} onClick={props.onReset}>
                <Icon name="undo" size={15} />
              </div>
            </Tooltip>
          )}
        </div>
      </animated.div>
    );
  }

  function renderBack() {
    return (
      <Formik
        initialValues={props.boundaries}
        enableReinitialize
        validationSchema={LegendSchema}
        onSubmit={async (values, actions) => {
          props.onCustomizeWithBoundary({ min: values.min, max: values.max });
          isFlipped.toggle();
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <animated.div
              className={cx(styles.back, {
                [styles.isActive]: isFlipped.value,
              })}
              style={{
                opacity,
                transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
              }}
            >
              <div className={styles.contentContainer}>
                <div className={styles.header}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.entity}>{entity}</div>
                  <div className={styles.close} onClick={props.onClose}>
                    <Icon name="times" size={15} />
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.formGroup}>
                    <div className={styles.formLabel}>{t('legend.min')}</div>
                    <Field
                      type="number"
                      id="min"
                      name="min"
                      step="0.0.1"
                      className={cx(styles.inputField, {
                        [styles.isInvalid]: errors.min && touched.min,
                      })}
                    />
                    <InvalidFeedback>
                      <ErrorMessage name="min" />
                    </InvalidFeedback>
                  </div>
                  <div className={styles.formGroup}>
                    <div className={styles.formLabel}>{t('legend.max')}</div>
                    <Field
                      type="number"
                      id="max"
                      name="max"
                      className={cx(styles.inputField, {
                        [styles.isInvalid]: errors.max && touched.max,
                      })}
                    />
                    <InvalidFeedback>
                      <ErrorMessage name="max" />
                    </InvalidFeedback>
                  </div>

                  <div className={styles.inlineActionGroup}>
                    {props.dynamicCustomize && (
                      <div
                        className={styles.inlineAction}
                        onClick={props.onCustomizeDynamic}
                      >
                        <Icon
                          name="paint-brush"
                          size={11}
                          fill="#474747"
                          className={styles.icon}
                        />
                        <span>{t('legend.actions.customizeDynamic')}</span>
                      </div>
                    )}

                    <div
                      className={styles.inlineAction}
                      onClick={() => {
                        isFlipped.toggle();
                        props.onReset();
                      }}
                    >
                      <Icon
                        name="undo"
                        size={11}
                        fill="#474747"
                        className={styles.icon}
                      />
                      <span>{t('legend.actions.reset')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <button className={styles.action} type="submit">
                  {t('legend.actions.ok')}
                </button>
              </div>
            </animated.div>
          </form>
        )}
      </Formik>
    );
  }
};

interface ScaleItemProps {
  data: APILegend['colorMap']['entries'][0];
}

const ScaleItem = (props: ScaleItemProps) => {
  const { data } = props;

  return (
    <div className={styles.scaleItem}>
      <div className={styles.scaleVisualContainer}>
        <div
          className={styles.scaleVisual}
          style={{ backgroundColor: data.color }}
        />
      </div>
      <div className={styles.scaleLabel}>{data.label}</div>
    </div>
  );
};
LegendDynamic.defaultProps = {
  type: 'ramp',
  dynamicCustomize: false,
  onCustomizeWithBoundary: noop,
  onCustomizeDynamic: noop,
  onReset: noop,
};

export { LegendDynamic };
