import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useBoolean } from 'react-hanger';
import { MapControl, MapControlButton } from 'marvin-ui-kit';
import { pick, noop } from 'lodash-es';
import wkx from 'wkx';
import { Metric, LayerData, APILegend } from '../types';
import { LegendDynamic } from '../components/legend-dynamic';
import Icon from '../components/icon';
import { getLegendMinMax } from '../services/legend.service';
import { latLngBoundsToGeometry } from '../utils/geometry.utils';
import { setLegendMinMax } from '../redux/selection/actions';

interface Props {
  legend: APILegend | null;
  customMinMax: { min: number; max: number } | null;
  metric: Metric | null;
  layer: LayerData | null;
  bounds: [[number, number], [number, number]] | null;
  onChange: (legend: APILegend) => void;
  dynamicCustomize: boolean;
}

export const LegendContainer = (props: Props) => {
  const { metric, layer, customMinMax, bounds, legend } = props;
  const dispatch = useDispatch();
  const isOpen = useBoolean(true);

  const legendMinMax = useMemo(() => {
    if (customMinMax) return customMinMax;
    if (metric) return pick(metric, ['min', 'max']);

    // return default min/max
    console.warn('Can not define legend min/max, fallback to default.');
    return { min: 0, max: -99999 };
  }, [customMinMax, metric]);

  function resetLegend() {
    if (!metric) {
      console.warn('Can not reset legend to without a metric.');
      return;
    }

    const { min, max } = metric;
    if (min === null || max === null) {
      console.warn('Can not reset legend without metric min/max.');
      return;
    }

    dispatch(setLegendMinMax(null));
  }

  async function customizeWithLegendDynamic() {
    if (!bounds) return;
    if (!layer?.guppyLayer) {
      console.warn("Can't customize legend without a guppyLayer");
      return;
    }

    const boundsGeometry = latLngBoundsToGeometry(bounds);
    if (boundsGeometry === null) {
      console.warn("Can't customize legend without a bounding box.");
      return;
    }

    const wkt = wkx.Geometry.parseGeoJSON(boundsGeometry.geometry).toWkt();
    const { min, max } = await getLegendMinMax(layer.guppyLayer, wkt);

    if (!min || !max) {
      console.warn("Can't customze legend dynamic with min or max `null`");
      return;
    }
    dispatch(setLegendMinMax({ min, max }));
  }

  function customizeWithBoundary(boundary: { min: number; max: number }) {
    const { min, max } = boundary;
    dispatch(setLegendMinMax({ min, max }));
  }
  return render();

  function render() {
    if (!metric || !legend) return null;

    if (isOpen.value === true) {
      return (
        <MapControl>
          <LegendDynamic
            title={metric.quantityName}
            legend={legend}
            boundaries={legendMinMax}
            dynamicCustomize={props.dynamicCustomize}
            entity={metric.unit}
            onCustomizeWithBoundary={customizeWithBoundary}
            onCustomizeDynamic={customizeWithLegendDynamic}
            onReset={resetLegend}
            onClose={isOpen.toggle}
          />
        </MapControl>
      );
    }

    return (
      <MapControlButton size="large" onClick={isOpen.setTrue}>
        <Icon name="legend" fill="black" />
      </MapControlButton>
    );
  }
};

LegendContainer.defaultProps = {
  onChange: noop,
  dynamicCustomize: false,
};
