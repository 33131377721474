import axios from 'axios';
import { Station } from '../types';

export async function getNRTStations(): Promise<Station[]> {
  const response = await axios.get(`/api/nrt/stations`);
  return response.data.data;
}

export async function getForecastStations(): Promise<Station[]> {
  const response = await axios.get(`/api/forecast/stations`);
  return response.data.data;
}

export async function getDashboardStations(): Promise<Station[]> {
  const response = await axios.get(`/api/dashboard/stations`);
  return response.data.data;
}
