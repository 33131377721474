import { SET_MAP_POSITION, MapState, MapActionTypes } from './types';
import { isEmpty, toNumber } from 'lodash-es';

console.log(window._env_);

const [lat, lng, zoom] = window._env_.REACT_APP_INITIAL_POSITION!.split(',');
const initialPosition = {
  lat: !isEmpty(lat) ? toNumber(lat) : 51.2195,
  lng: !isEmpty(lng) ? toNumber(lng) : 5.089,
  zoom: !isEmpty(zoom) ? toNumber(zoom) : 14,
};

export const initialState: MapState = {
  position: initialPosition,
};

export const mapReducer = (
  state = initialState,
  action: MapActionTypes
): MapState => {
  switch (action.type) {
    case SET_MAP_POSITION:
      return {
        ...state,
        position: action.payload,
      };

    default:
      return state;
  }
};
