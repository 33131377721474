import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as JSON5 from 'json5';

import Backend from 'i18next-xhr-backend';
import { i18nInterceptor } from './interceptors';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: 'en',

      // do not load a fallback
      fallbackLng: false,
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json5',
        parse: JSON5.parse,
      },
    },
    function callback() {
      // TODO: this doens't halt the Suspense, must be fixed
      return new Promise(res => {
        i18nInterceptor(i18n.language);
        setTimeout(() => {
          res();
        }, 10000);
      });
    }
  );

export default i18n;
