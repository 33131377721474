import axios from 'axios';
import { Metric, Station } from '../types';

export async function getNrtMetrics(): Promise<Metric[]> {
  const response = await axios.get(`/api/nrt/metrics`, {
    headers: { Accept: 'application/json' },
  });
  return response.data.data;
}

export async function getForecastMetrics(): Promise<Metric[]> {
  const response = await axios.get(`/api/forecast/metrics`);
  return response.data.data;
}

export async function getForecastDashboardMetrics(
  station: Station
): Promise<Metric[]> {
  const response = await axios.get(`/api/forecast/metrics`, {
    params: { stationId: station.id },
  });
  return response.data.data;
}

export async function getHistoricalMetrics(): Promise<Metric[]> {
  const response = await axios.get(`/api/historical/metrics`);
  return response.data.data;
}
