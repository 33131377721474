import React from 'react';
import Control from 'react-leaflet-control';
import { formatDate } from '../../utils/format.utils';
import { LayerData } from '../../types';
import styles from './styles.module.scss';

export const LayerCreatedAt = (props: { layer: LayerData }) => {
  const createdAt = formatDate(props.layer.mapDate * 1000, 'dd/MM/y');
  return (
    <Control position="bottomright">
      <div className={styles.container}>{createdAt}</div>
    </Control>
  );
};
