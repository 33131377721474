// parse react-select onChange value from option
export function getSelectValue(option: any) {
  if (!option) {
    // Handle the undefined / null scenario here
    return option;
  } else if (option instanceof Array) {
    // Handle an array here
    // You will likely get an array if you have enabled
    // isMulti prop
  } else {
    return option.value;
  }
}
