import React, { useState, useEffect, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Metric } from '../../types';
import * as metricService from '../../services/metric.service';
import * as nrtAnimationService from '../../services/nrt-animation.service';
import { getNrtAnimationFormSelection } from '../../selectors';
import styles from './styles.module.scss';
import { formatMetricName } from '../../utils/format.utils';
import { Button } from '../../components/button';
import { DateRangePicker } from '../../components/date-range-picker';
import { Label, FormGroup } from '../../components/form';
import { NrtAnimationSelection } from '../../redux/selection/types';
import { getSelectValue } from '../../utils/form.utils';

interface Props {
  onSubmit: (data: NrtAnimationSelection) => void;
}

export const NrtAnimationForm = (props: Props) => {
  const { t } = useTranslation();
  const formData = useSelector(getNrtAnimationFormSelection);

  const [selectedYear, setSelectedYear] = useState(
    formData.dateRange.from.getFullYear()
  );
  const [datesWithData, setDatesWithData] = useState<Date[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [selectedMetricId, setSelectedMetricId] = useState<number | null>(
    formData.metricId
  );
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date }>(
    formData.dateRange
  );

  /**
   * Effects
   */
  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setMetrics(initialData.metrics);
    });
  }, []);

  // fetch availlable dates for selected year / metric
  useEffect(() => {
    if (!selectedMetricId) return;

    nrtAnimationService
      .getDates(selectedMetricId, selectedYear)
      .then((dates) =>
        setDatesWithData(dates.map((date) => new Date(date * 1000)))
      );
  }, [selectedMetricId, selectedYear]);

  /**
   * Functions
   */
  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const formData = {
      metricId: selectedMetricId,
      dateRange: dateRange,
    };
    props.onSubmit(formData);
  }

  /**
   * Render
   */
  return render();

  function render() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>{t('metric')}</Label>

          {renderMetricSelect()}
        </FormGroup>

        <FormGroup>
          <Label>{t('dateRange')}</Label>

          <div className={styles.dateRange}>
            <DateRangePicker
              maxRange={7}
              dateRange={dateRange}
              datesWithData={datesWithData}
              onRangeSelect={setDateRange}
              onYearChange={setSelectedYear}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Button>{t('actions.load')}</Button>
        </FormGroup>
      </form>
    );
  }

  function renderMetricSelect() {
    const options = metrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      setSelectedMetricId(metricId);
    }
    return (
      <Select
        value={
          options.find((option) => option.value === selectedMetricId) || null
        }
        placeholder={t('metricPlaceholder')}
        options={options}
        onChange={(e) => handleChange(getSelectValue(e))}
      ></Select>
    );
  }
};

interface InitialData {
  metrics: Metric[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const metrics = await metricService.getNrtMetrics();
  return { metrics };
}
