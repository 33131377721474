import { LeafletMouseEvent, LatLng } from 'leaflet';
import { round } from 'lodash-es';

export interface MapEventData {
  x: number;
  y: number;
  size: {
    x: number;
    y: number;
  };
  bbox: string;
  latlng: LatLng;
}

export function getLeafletEventData(event: LeafletMouseEvent): MapEventData {
  // round containerPoints for specific browsers (e.g. Chrome specific version)
  const x = round(event.containerPoint.x);
  const y = round(event.containerPoint.y);
  const size = event.target._size;
  const bbox = event.target.getBounds().toBBoxString();
  const latlng = event.latlng;

  return { x, y, size, bbox, latlng };
}
