// Polyfill old browsers
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { RootContainer } from './containers/root.container';
import history from './history';

import L from 'leaflet'; // eslint-disable-line
import Draw from 'leaflet-draw'; // eslint-disable-line
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-day-picker/lib/style.css';
import './styles/app.scss';

import './i18n';
import { Preloader } from './components/preloader';

// initialize axios interceptors
import './interceptors';

const MOUNT_NODE = document.getElementById('root');

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense
        fallback={<Preloader isLoading loadingText="Loading..."></Preloader>}
      >
        <Router history={history}>
          <Route path="/" component={RootContainer} />
        </Router>
      </Suspense>
    </Provider>,
    MOUNT_NODE
  );
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
