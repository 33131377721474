import { SET_AUTH_TOKENS, AuthActionTypes } from './types';

export function setAuthTokens(
  accessToken: string | null,
  refreshToken: string | null
): AuthActionTypes {
  return {
    type: SET_AUTH_TOKENS,
    payload: {
      accessToken,
      refreshToken,
    },
  };
}
