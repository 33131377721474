import React, { useState, useRef, useEffect, useMemo } from 'react';
import { clamp, times } from 'lodash-es';
import { isSameDay } from 'date-fns/esm';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import classnames from 'classnames';

import { formatDate } from '../../utils/format.utils';
import { AnimationImage } from '../../types';
import styles from './styles.module.scss';

interface Props {
  images: AnimationImage[];
  activeIndex: number;
  onChange: (index: number) => void;
}

export const AnimationStripFrameControl = (props: Props) => {
  // destructure props;
  const { images, activeIndex } = props;

  const blockSize = 40;
  const [limits, setLimits] = useState([-(images.length * blockSize), 0]);
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ x }, set] = useSpring(() => ({
    x: 0,
    config: { mass: 1, tension: 350, friction: 40 },
  }));

  const snapPositions = useMemo(
    () => times(images.length, (i: number) => i * -blockSize),
    [images]
  );

  // set limits
  useEffect(() => {
    function handleResize() {
      if (containerRef.current) {
        const lowerLimit = -(images.length * blockSize);
        const upperLimit = 0;
        setLimits([lowerLimit, upperLimit]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, images]);

  useEffect(() => {
    set({ x: snapPositions[activeIndex], pointerevents: false });
  }, [activeIndex, set, snapPositions]);

  const bind = useDrag(
    ({ event, movement, down, first, last, memo = [x.getValue()] }) => {
      const [lowerLimit, upperLimit] = limits;
      const newX = clamp(memo[0] + movement[0], lowerLimit, upperLimit);

      if (down) {
        // update based on movement
        set({ x: newX, pointerevents: false });
      } else {
        // update by snapping to availlable position
        const snappedX = snapValue(newX, snapPositions);
        set({ x: snappedX, pointerevents: false });

        // set new active image
        const newIndex = snapPositions.indexOf(snappedX);
        props.onChange(newIndex);
      }

      return memo;
    },
    {
      dragDelay: 1000,
    }
  );

  function snapValue(exactValue: number, snaps: number[]): number {
    return snaps.reduce((closestSnap, snap) => {
      const closestDifference = Math.abs(closestSnap - exactValue);
      const difference = Math.abs(snap - exactValue);

      if (difference < closestDifference) return snap;
      return closestSnap;
    }, 0);
  }

  // render the component
  return render();

  function render() {
    const activeImage = images[activeIndex];

    return (
      <div ref={containerRef} className={styles.container}>
        <div
          className={classnames(styles.activeNeedle, {
            [styles.isUnavailable]: activeImage
              ? activeImage.id === null
              : false,
          })}
        />

        <animated.div
          className={styles.timeline}
          {...bind()}
          style={{
            x,
          }}
        >
          <div className={styles.whiteSpaceHandle} />

          {images.map((image, index) => {
            const { mapDate: timestamp } = image;
            const previousLayer = images[index - 1];

            const showDay = !isSameDay(
              timestamp * 1000,
              previousLayer ? previousLayer.mapDate * 1000 : -1
            );

            const isActive = index === props.activeIndex;
            const isUnavailable = image.id === null;

            return (
              <div
                key={timestamp}
                className={classnames(styles.timeBlock, {
                  [styles.isActive]: isActive,
                  [styles.isUnavailable]: isUnavailable,
                })}
                style={{ width: blockSize }}
                onMouseUp={() => {
                  props.onChange(index);
                }}
              >
                <div className={styles.tick}></div>
                {showDay && (
                  <>
                    <div className={styles.dayTick}></div>
                    <div className={styles.dayLabel}>
                      {formatDate(timestamp * 1000, 'dd/MM/y')}
                    </div>
                  </>
                )}
                <div className={styles.timeLabel}>
                  {formatDate(timestamp * 1000, 'H')}h
                </div>
              </div>
            );
          })}

          <div className={styles.whiteSpaceHandle} />
        </animated.div>
      </div>
    );
  }
};
