import React, { useMemo } from 'react';
import { map, filter, sortBy } from 'lodash-es';
import Plot from 'react-plotly.js';
import { PlotData } from 'plotly.js';
import { useTranslation } from 'react-i18next';
import { Station } from '../../types';
import Icon from '../icon';
import { parseMetrics } from '../../utils/data.utils';
import styles from './styles.module.scss';

interface Props {
  station: Station;
  onClose: () => void;
}

export const TimeseriesChart = (props: Props) => {
  const { t } = useTranslation();
  const { station } = props;

  const traces: Partial<PlotData>[] = useMemo(() => {
    const { values } = station;

    const metrics = parseMetrics(values);

    // create a trace for every metric
    const traces = metrics.map(metric => {
      const dataByMetric = sortBy(
        filter(values, {
          metricId: metric.id,
        }),
        'datetimeStart'
      );

      const plotData: Partial<PlotData> = {
        type: 'scatter',
        mode: 'lines+markers',
        name: metric.name,
        x: map(dataByMetric, o => {
          return new Date(o.datetimeStart * 1000);
        }),
        y: map(dataByMetric, dataItem => {
          if (dataItem.value !== dataItem.missingValue) return dataItem.value;
          return null;
        }),
      };

      return plotData;
    });

    return traces;
  }, [station]);

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span>
            {t('stationPopup.station')}: {station.localCode}
          </span>
          <div className={styles.buttonClose} onClick={props.onClose}>
            <Icon name="times" fill="#A3A3A3" size={11} />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.chart}>
            <Plot
              style={{ width: '100%', height: '100%' }}
              data={traces}
              layout={{
                autosize: true,
                legend: { orientation: 'v' },
                xaxis: { automargin: true, zeroline: false },
                yaxis: {
                  automargin: true,
                  zeroline: false,
                  tickformat: '.1f',
                  ticksuffix: ' µg/m³',
                },
                showlegend: true,
                margin: { t: 40, b: 0, l: 0, r: 0 },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};
