import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import { mapReducer } from './map/reducers';
import { selectionReducer } from './selection/reducers';
import { basedataReducer } from './basedata/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  basedata: basedataReducer,
  map: mapReducer,
  selection: selectionReducer,
});

export default rootReducer;
