import React, { useMemo, HTMLAttributes } from 'react';
import { PlotData, Shape } from 'plotly.js';
import Plot from 'react-plotly.js';
import { useTranslation } from 'react-i18next';

import { DashboardValidationData } from '../services/dashboard.service';
import { parseModels } from '../utils/data.utils';

interface OwnProps {
  activeModelIds: number[];
  data: DashboardValidationData;
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>;

export const ForecastDashboardScatterPlot = (props: Props) => {
  const { t } = useTranslation();
  const { activeModelIds, data } = props;

  const models = useMemo(() => {
    return parseModels(data.values).filter((model) =>
      activeModelIds.includes(model.id)
    );
  }, [activeModelIds, data.values]);

  const traces = useMemo(() => {
    // define a trace per model
    const traces = models.map((model) => {
      const dataForModel = data.scatterData.filter(
        (dataItem) => dataItem.modelId === model.id
      );
      const x = dataForModel.map((scatterItem) => {
        return scatterItem.values[0];
      });
      const y = dataForModel.map((scatterItem) => {
        return scatterItem.values[1];
      });

      const plotData: Partial<PlotData> = {
        x,
        y,
        hovertemplate: t('dashboard.validation.scatterGraph.hover', {
          model: model.name,
        }),
        mode: 'markers',
        type: 'scatter',
        marker: { size: 8, color: model.color },
        name: '',
      };

      return plotData;
    });
    return traces;
  }, [data.scatterData, models, t]);

  const domain = useMemo(() => {
    return [
      0,
      Math.max(
        ...data.scatterData.map((item) => item.values[0]),
        ...data.scatterData.map((item) => item.values[1])
      ) * 1.05, // increase domain max with 5% to allow drawing space in fixed ratio graph
    ];
  }, [data.scatterData]);

  const identificationLines: Partial<Shape>[] = [
    // x/2 identification line
    {
      type: 'line',
      x0: domain[0],
      y0: domain[0],
      x1: domain[1] / 2,
      y1: domain[1],
      line: {
        color: '#7B7B7B',
        width: 1,
        dash: 'dash',
      },
    },

    // y/2 identification line
    {
      type: 'line',
      x0: domain[0],
      y0: domain[0],
      x1: domain[1],
      y1: domain[1] / 2,
      line: {
        color: '#7B7B7B',
        width: 1,
        dash: 'dash',
      },
    },

    // identification line
    {
      type: 'line',
      x0: domain[0],
      y0: domain[0],
      x1: domain[1],
      y1: domain[1],
      line: {
        color: '#303030',
        width: 1,
        dash: 'solid',
      },
    },
  ];

  // render the component
  return render();

  function render() {
    return (
      <div className={props.className} style={{ ...props.style }}>
        <Plot
          style={{ width: '100%', height: '100%' }}
          data={traces}
          layout={{
            autosize: true,
            showlegend: false,
            hovermode: 'closest',
            hoverlabel: { bgcolor: '#FFF' },

            margin: { r: 0, b: 20 },
            xaxis: {
              range: domain,
              zeroline: false,
              tickformat: '.1f',
              automargin: true,
              title: t('dashboard.validation.scatterGraph.observation'),
            },
            yaxis: {
              range: domain,
              zeroline: false,
              tickformat: '.1f',
              automargin: true,
              title: t('dashboard.validation.scatterGraph.model'),
            },
            shapes: [...identificationLines],
          }}
        />
      </div>
    );
  }
};
