import React, { useState, useEffect, useRef } from 'react';
import { clamp, isEmpty } from 'lodash-es';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import classnames from 'classnames';
import { isSameDay } from 'date-fns/esm';

import { LayerData } from '../../types';
import Icon from '../icon';
import styles from './styles.module.scss';
import { formatDate } from '../../utils/format.utils';

interface Props {
  layers: LayerData[];
  activeTimestamp: number | null;
  onClick: (timestamp: number) => void;
}

export const TimeframeControl = (props: Props) => {
  const { layers } = props;
  const blockSize = 40;
  const [limits, setLimits] = useState([-(layers.length * blockSize), 0]);
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ x }, set] = useSpring(() => ({
    x: 0,
    config: { mass: 1, tension: 350, friction: 40 },
  }));

  const bind = useDrag(({ movement, memo = [x.getValue()] }) => {
    const [lowerLimit, upperLimit] = limits;

    const newX = clamp(memo[0] + movement[0], lowerLimit, upperLimit);
    set({ x: newX, pointerevents: false });

    return memo;
  });

  // set initial x position
  useEffect(() => {
    if (containerRef.current) {
      set({ x: -(layers.length * blockSize), immediate: true });
    }
  }, [set, layers.length]);

  // set limits
  useEffect(() => {
    function handleResize() {
      if (containerRef.current) {
        const lowerLimit = -(layers.length * blockSize);
        const upperLimit = 0;
        setLimits([lowerLimit, upperLimit]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, layers]);

  function moveTimeline(direction: 'left' | 'right') {
    const [lowerLimit, upperLimit] = limits;
    const movementSize = blockSize * 2;
    if (direction === 'left') {
      if (x.getValue() - movementSize < lowerLimit) {
        set({ x: lowerLimit });
      } else {
        set({ x: x.getValue() - movementSize });
      }
    }

    if (direction === 'right') {
      if (x.getValue() + movementSize > upperLimit) {
        set({ x: upperLimit });
      } else {
        set({ x: x.getValue() + movementSize });
      }
    }
  }

  // render the component
  return render();

  function render() {
    // if no layers, render empty
    if (isEmpty(layers)) renderEmpty();

    return (
      <div ref={containerRef} className={styles.container}>
        <div
          className={classnames(
            styles.timelineControl,
            styles.timelineControlLeft
          )}
          onClick={() => moveTimeline('right')}
        >
          <Icon name="angle-left" size={12} />
          <animated.div
            className={styles.shadow}
            {...bind()}
            style={{
              opacity: x.interpolate({
                range: [limits[0], limits[1] - 50, limits[1]],
                output: [1, 1, 0],
              }),
            }}
          ></animated.div>
        </div>
        <animated.div
          className={styles.timeline}
          {...bind()}
          style={{
            x,
          }}
        >
          <div className={styles.whiteSpaceHandle} />

          {layers.map((layer, i) => {
            const { mapDate: timestamp } = layer;

            const previousLayer = layers[i - 1];

            const showDay = !isSameDay(
              timestamp * 1000,
              previousLayer ? previousLayer.mapDate * 1000 : -1
            );

            const isActive = timestamp === props.activeTimestamp;
            const isUnavailable = layer.id === null;

            return (
              <div
                key={timestamp}
                className={classnames(styles.timeBlock, {
                  [styles.isActive]: isActive,
                  [styles.isUnavailable]: isUnavailable,
                })}
                style={{ width: blockSize }}
                onClick={() => !isUnavailable && props.onClick(timestamp)}
              >
                <div className={styles.tick}></div>
                {showDay && (
                  <>
                    <div className={styles.dayTick}></div>
                    <div className={styles.dayLabel}>
                      {formatDate(new Date(timestamp * 1000), 'dd/MM/y')}
                    </div>
                  </>
                )}
                <div className={styles.timeLabel}>
                  {formatDate(new Date(timestamp * 1000), 'H')}h
                </div>
              </div>
            );
          })}

          <div className={styles.whiteSpaceHandle} />
        </animated.div>
        <div
          className={classnames(
            styles.timelineControl,
            styles.timelineControlRight
          )}
          onClick={() => moveTimeline('left')}
        >
          <Icon name="angle-right" size={12} />
          <animated.div
            className={styles.shadow}
            {...bind()}
            style={{
              opacity: x.interpolate({
                range: [limits[0], limits[0] + 50, limits[1]],
                output: [0, 1, 1],
              }),
            }}
          ></animated.div>
        </div>
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className={styles.container}>No data availlable for this metric</div>
    );
  }
};
