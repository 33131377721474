import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { AppContainer } from './app.container';
import { AuthLayout } from '../layouts/auth.layout';
import { LoginContainer } from './login.container';
import { ResetPasswordContainer } from './reset-password.container';
import { PrivateRoute } from '../components/private.route';
import { ForgotPasswordContainer } from './forgot-password.container.tsx';
import { useApplicationTitle } from '../hooks/use-application-title';

export function RootContainer() {
  useApplicationTitle();

  const publicRoutes = [
    '/register',
    '/login',
    '/forgot-password',
    '/reset-password',
  ];

  return render();

  function render() {
    return (
      <Switch>
        <Route path={publicRoutes}>
          <AuthLayout>
            <Switch>
              <Route path="/login" component={LoginContainer} />
              <Route
                path="/forgot-password"
                component={ForgotPasswordContainer}
              />
              <Route
                path="/reset-password"
                component={ResetPasswordContainer}
              />
            </Switch>
          </AuthLayout>
          );
        </Route>
        <PrivateRoute
          path={['/*']}
          component={AppContainer}
          isAuthenticated={true}
        />
        <Redirect to="/" />
      </Switch>
    );
  }
}
