import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { useInterval } from '../../hooks';
import Icon from '../icon';

import styles from './styles.module.scss';
import { AnimationImage } from '../../types';
import { find } from 'lodash-es';
import { getSelectValue } from '../../utils/form.utils';
import styled from 'styled-components';

interface Props {
  activeIndex: number;
  images: AnimationImage[];
  onChange: (index: number) => void;
}

export const AnimationControlButtons = (props: Props) => {
  const { t } = useTranslation();

  const delay = 1000;
  const [speed, setSpeed] = useState(2);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(() => goToNext(), isRunning ? delay / speed : null);

  const isAtStart = useMemo(() => props.activeIndex === 0, [props.activeIndex]);
  const isAtEnd = useMemo(() => props.activeIndex === props.images.length - 1, [
    props.activeIndex,
    props.images.length,
  ]);
  const isDisabled = useMemo(() => props.images.length === 0, [
    props.images.length,
  ]);

  useEffect(() => {
    if (isAtEnd) setIsRunning(false);
  }, [isAtEnd]);

  /**
   * Functions
   */
  function goToStart() {
    if (isDisabled) return;
    props.onChange(0);
  }

  function goToEnd() {
    if (isDisabled) return;
    props.onChange(props.images.length - 1);
  }

  function goToNext() {
    if (isDisabled || isAtEnd) return;
    props.onChange(props.activeIndex + 1);
  }

  function goToPrevious() {
    if (isDisabled || isAtStart) return;
    props.onChange(props.activeIndex - 1);
  }

  function play() {
    if (isDisabled) return;
    setIsRunning(true);
  }

  function pause() {
    if (isDisabled) return;
    setIsRunning(false);
  }

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.controls}>
          <button
            className={cx(styles.control, {
              [styles.disabled]: isDisabled || isAtStart,
            })}
            onClick={() => goToStart()}
          >
            <Icon name="arrow-to-left" fill="white" size={11} />
          </button>

          <button
            className={cx(styles.control, {
              [styles.disabled]: isDisabled || isAtStart,
            })}
            onClick={() => goToPrevious()}
          >
            <Icon name="angle-double-left" fill="white" size={15} />
          </button>

          {!isRunning && (
            <button
              className={cx(styles.control, {
                [styles.disabled]: isDisabled || isAtEnd,
              })}
              onClick={play}
            >
              <Icon name="play" fill="white" size={11} />
            </button>
          )}

          {isRunning && (
            <button
              className={cx(styles.control, {
                [styles.disabled]: isDisabled,
              })}
              onClick={pause}
            >
              <Icon name="pause" fill="white" size={11} />
            </button>
          )}

          <button
            className={cx(styles.control, {
              [styles.disabled]: isDisabled || isAtEnd,
            })}
            onClick={() => goToNext()}
          >
            <Icon name="angle-double-right" fill="white" size={15} />
          </button>

          <button
            className={cx(styles.control, {
              [styles.disabled]: isDisabled || isAtEnd,
            })}
            onClick={() => goToEnd()}
          >
            <Icon name="arrow-to-right" fill="white" size={11} />
          </button>
        </div>

        {renderSpeedSelect()}
      </div>
    );
  }

  function renderSpeedSelect() {
    const customStyles = {
      control: (provided: any) => ({
        ...provided,
        width: 'fit-content',
      }),
      indicatorSeparator: () => ({}),
    };
    const speeds = [2, 4, 8];
    const options = speeds.map(speed => ({ value: speed, label: speed }));
    function handleChange(speed: number) {
      setSpeed(speed);
    }
    return (
      <SpeedSelect>
        <div className="label">Speed:</div>
        <Select
          className="select"
          styles={customStyles}
          value={find(options, { value: speed })}
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={e => handleChange(getSelectValue(e))}
        ></Select>
      </SpeedSelect>
    );
  }
};

const SpeedSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    padding-right: 10px;
  }
`;
