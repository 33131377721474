import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getYear } from 'date-fns/esm';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash-es';

import { Station, Metric } from '../../../types';
import { getForecastDashboardValidationSelection } from '../../../selectors';
import { getForecastStations } from '../../../services/station.service';
import { formatMetricName } from '../../../utils/format.utils';
import { getForecastDashboardMetrics } from '../../../services/metric.service';
import { Button } from '../../../components/button';
import { DateRangePicker } from '../../../components/date-range-picker';
import {
  getDashboardForecastHorizon,
  getDashboardDates,
} from '../../../services/dashboard.service';
import styles from './styles.module.scss';
import { FormGroup, Label } from '../../../components/form';
import { getSelectValue } from '../../../utils/form.utils';

export interface DashboardValidationFormData {
  station: Station;
  metricId: number;
  horizon: number;
  dateRange: {
    from: Date;
    to: Date;
  };
}

interface Props {
  onSubmit: (data: DashboardValidationFormData) => void;
}

export function ForecastDashboardValidationForm(props: Props) {
  const { t } = useTranslation();

  // select redux state
  const formData = useSelector(getForecastDashboardValidationSelection);

  // component state
  const [selectedStation, setSelectedStation] = useState(formData.station);
  const [selectedMetricId, setSelectedMetricId] = useState(formData.metricId);
  const [selectedHorizon, setSelectedHorizon] = useState(formData.horizon);
  const [selectedDateRange, setSelectedDateRange] = useState(
    formData.dateRange
  );
  const [allStations, setAllStations] = useState<Station[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [horizons, setHorizons] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState(
    getYear(selectedDateRange.from)
  );
  const [datesWithData, setDatesWithData] = useState<Date[]>([]);

  async function handleSubmit() {
    if (
      selectedStation !== null &&
      selectedMetricId !== null &&
      selectedHorizon !== null &&
      selectedDateRange !== null
    ) {
      props.onSubmit({
        station: selectedStation,
        metricId: selectedMetricId,
        horizon: selectedHorizon,
        dateRange: selectedDateRange,
      });
    }
  }

  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setAllStations(initialData.stations);
    });
  }, []);

  useEffect(() => {
    if (!selectedStation) return setMetrics([]);

    getForecastDashboardMetrics(selectedStation).then((metrics) => {
      setMetrics(metrics);
      // reset if necesseary
      const metricIds = metrics.map((m) => m.id);
      if (!metricIds.includes(selectedMetricId as number))
        setSelectedMetricId(null);
    });
  }, [selectedMetricId, selectedStation]);

  useEffect(() => {
    if (!selectedStation || !selectedMetricId) return;

    getDashboardForecastHorizon(selectedStation.id, selectedMetricId).then(
      (horizons) => {
        setHorizons(horizons);

        // reset if necesseary
        if (!horizons.includes(selectedHorizon as number))
          setSelectedHorizon(null);
      }
    );
  }, [selectedStation, selectedMetricId, selectedHorizon]);

  // fetch availlable dates for selected year / metric
  useEffect(() => {
    if (!selectedStation || !selectedMetricId) return;

    getDashboardDates(selectedStation.id, selectedMetricId, selectedYear).then(
      setDatesWithData
    );
  }, [selectedStation, selectedMetricId, selectedYear]);

  return render();

  function render() {
    return (
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {renderStationSelect()}
        {renderMetricSelect()}
        {renderHorizonSelect()}
        {renderDateRange()}

        <FormGroup>
          <Button type="submit">{t('actions.load')}</Button>
        </FormGroup>
      </form>
    );
  }

  function renderStationSelect() {
    const options = allStations.map((station) => ({
      value: station.id,
      label: `${station.description} - ${station.localCode}`,
    }));

    function handleChange(stationId: number) {
      const station = find(allStations, { id: stationId });
      if (station) setSelectedStation(station);
    }
    return (
      <FormGroup>
        <Label>{t('station')}</Label>

        <Select
          className="select"
          value={find(options, { value: selectedStation?.id })}
          placeholder={t('stationPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </FormGroup>
    );
  }
  function renderMetricSelect() {
    const options = metrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      if (metricId) setSelectedMetricId(metricId);
    }

    return (
      <FormGroup>
        <Label>{t('metric')}</Label>
        <Select
          className="select"
          value={
            options.find((option) => option.value === selectedMetricId) || null
          }
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </FormGroup>
    );
  }

  function renderHorizonSelect() {
    const options = horizons.map((horizon) => ({
      value: horizon,
      label: horizon,
    }));

    function handleChange(horizon: number) {
      setSelectedHorizon(horizon);
    }

    return (
      <FormGroup>
        <Label>{t('horizon')}</Label>
        <Select
          className="select"
          value={
            selectedHorizon !== null
              ? options.find((option) => option.value === selectedHorizon)
              : null
          }
          placeholder={t('horizonPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </FormGroup>
    );
  }

  function renderDateRange() {
    function handleChange(dateRange: { from: Date; to: Date }) {
      setSelectedDateRange(dateRange);
    }

    return (
      <FormGroup>
        <Label>{t('dateRange')}</Label>
        <div className={styles.dateRange}>
          <DateRangePicker
            dateRange={selectedDateRange}
            datesWithData={datesWithData}
            onRangeSelect={handleChange}
            onYearChange={setSelectedYear}
          />
        </div>
      </FormGroup>
    );
  }
}

interface InitialData {
  stations: Station[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const stations = await getForecastStations();
  return { stations };
}
