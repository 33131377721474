import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { parseModels } from '../../../utils/data.utils';
import { getForecastDashboardValidationSelection } from '../../../selectors';
import {
  DashboardValidationData,
  getDashboardValidationData,
} from '../../../services/dashboard.service';
import {
  ForecastDashboardValidationForm,
  DashboardValidationFormData,
} from './forecast-dashboard-validation-form';
import { H3 } from '../../../components/typography';
import { DashboardValidationTable } from '../../../components/dashboard-validation-table';
import { DashboardValidationDailyChart } from '../../../components/dashboard-validation-daily-chart';
import { ForecastDashboardScatterPlot } from '../../../components/dashboard-validation-scatter-plot';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useDimensions } from '../../../hooks';
import { setForecastDashboardValidationSelection } from '../../../redux/selection/actions';
import { Metric } from '../../../types';
import { getForecastMetrics } from '../../../services/metric.service';

interface Props {}

export const ForecastDashboardValidation = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ref, dimensions } = useDimensions();

  const formData = useSelector(getForecastDashboardValidationSelection);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [data, setData] = useState<DashboardValidationData | null>(null);
  const [activeModelIds, setActiveModelIds] = useState<number[] | null>(null);

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );

  // fetch initial data
  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setMetrics(initialData.metrics);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // populate view with initial data
  useEffect(() => {
    const { station, metricId, horizon, dateRange } = formData;
    if (station && metricId && horizon && dateRange) {
      getDashboardValidationData(station.id, metricId, horizon, dateRange).then(
        setData
      );
    }
  }, [formData]);

  async function handleFormSubmit(formData: DashboardValidationFormData) {
    dispatch(setForecastDashboardValidationSelection(formData));

    const data = await getDashboardValidationData(
      formData.station.id,
      formData.metricId,
      formData.horizon,
      formData.dateRange
    );
    setData(data);
    activateAllModels(data);
  }

  function activateAllModels(data: DashboardValidationData) {
    if (data) {
      const models = parseModels(data.values);
      setActiveModelIds(models.map((d) => d.id));
    }
  }

  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <ForecastDashboardValidationForm onSubmit={handleFormSubmit} />
        </div>

        <div className={styles.main}>
          <div className={styles.tableContainer}>
            <H3>
              {t('dashboard.validation.statistics.title', {
                metric: activeMetric?.quantityName,
                unit: activeMetric?.unit,
              })}
            </H3>

            {data && (
              <DashboardValidationTable
                className={styles.statisticsTable}
                data={data}
                activeModelIds={activeModelIds || []}
                onActiveModelsChange={setActiveModelIds}
              />
            )}
          </div>

          <div ref={ref} className={styles.scatterGraph}>
            <H3>
              {t('dashboard.validation.scatterGraph.title', {
                metric: activeMetric?.quantityName,
                unit: activeMetric?.unit,
              })}
            </H3>
            {data && (
              <ForecastDashboardScatterPlot
                style={{ width: '100%', height: dimensions.width }}
                activeModelIds={activeModelIds || []}
                data={data}
              />
            )}
          </div>

          <div className={styles.dailyChart}>
            <H3>
              {t('dashboard.validation.dailyChart.title', {
                metric: activeMetric?.quantityName,
                unit: activeMetric?.unit,
              })}
            </H3>

            {data && (
              <DashboardValidationDailyChart
                activeModelIds={activeModelIds || []}
                data={data}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const metrics = await getForecastMetrics();

  return { metrics };
}

interface InitialData {
  metrics: Metric[];
}
