import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TileLayer, WMSTileLayer } from 'react-leaflet';
import { find, pick } from 'lodash-es';
import {
  MapControlGroup,
  MapControl,
  Map,
  MapControlButton,
} from 'marvin-ui-kit';
import { isSameYear } from 'date-fns/esm';
import { LeafletMouseEvent } from 'leaflet';
import { useBoolean } from 'react-hanger';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { Metric, LayerData, Model, APILegend } from '../../types';
import {
  getBaselayers,
  getLegendMinMaxSelection,
  getHistoricalMapsFormSelection,
  getDrawing,
} from '../../selectors';
import {
  setHistoricalMapsSelection,
  setLegendMinMax,
  setDrawing,
} from '../../redux/selection/actions';
import { getHistoricalModels } from '../../services/model.service';
import { getHistoricalMaps } from '../../services/map.service';
import { getHistoricalMetrics } from '../../services/metric.service';
import { getLegend, getLegendSldUrl } from '../../services/legend.service';
import { LegendContainer } from '../legend.container';
import Icon from '../../components/icon';
import { LayerSelection } from '../../components/historical-layer-selection';
import { RasterCellPopup } from '../../components/raster-cell-popup';
import { MapEventData, getLeafletEventData } from '../../utils/leaflet.utils';
import { LayerCreatedAt } from '../../components/layer-created-at';
import { MapBounds } from '../../components/map-bounds';
import { SelectionGroup } from '../../components/selection-group';
import { DrawControl } from '../../components/draw-control';
import { LeafletDisableClickPropagation } from '../../components/leaflet-disable-click-propagation';
import { useMapPosition } from '../../hooks';
import { GraphContainer } from '../graph.container/graph.container';
import styles from './styles.module.scss';

interface Props {}

export const HistoricalMapsContainer = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // select state from redux
  const formData = useSelector(getHistoricalMapsFormSelection);
  const baselayers = useSelector(getBaselayers);
  const legendMinMaxSelection = useSelector(getLegendMinMaxSelection);
  const drawing = useSelector(getDrawing);

  // component state
  const isDrawing = useBoolean(false);
  const [legend, setLegend] = useState<APILegend | null>(null);
  const [layers, setLayers] = useState<LayerData[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [rasterCellData, setRasterCellData] = useState<MapEventData | null>(
    null
  );
  const [bounds, setBounds] = useState<
    [[number, number], [number, number]] | null
  >(null);
  const { position, setMapPosition, setMapZoom } = useMapPosition();

  // destructure selection state
  const { model: activeModel, year: activeYear } = formData;

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );

  const activeLayer = useMemo(() => {
    if (!layers || !activeMetric || !activeYear) {
      return null;
    }

    const layer = layers.find((layer) => {
      // layer matches the active model?
      if (layer.modelId !== activeModel?.id) return false;

      // layer matches the active metric?
      if (layer.metricId !== activeMetric.id) return false;

      // layer matches the active year?
      if (!isSameYear(new Date(activeYear, 1, 1), layer.mapDate * 1000))
        return false;

      // layer found
      return true;
    });

    return layer || null;
  }, [layers, activeMetric, activeYear, activeModel]);

  const legendMinMax = useMemo(() => {
    if (legendMinMaxSelection) return legendMinMaxSelection;
    if (activeMetric) return pick(activeMetric, ['min', 'max']);

    // return default legend
    console.warn('Can not define legend min/max, fallback to default.');
    return { min: 0, max: 50 };
  }, [legendMinMaxSelection, activeMetric]);

  // Create layers array, creating an array in the component composition triggers a new rerender and effect trigger every render cycle.
  //   component composition: <GraphContainer layers={[activeLayer]}   />
  const graphLayers: LayerData[] = useMemo(() => {
    if (activeLayer) return [activeLayer];
    return [];
  }, [activeLayer]);

  const metricRasterValues = useMemo(() => {
    if (!activeLayer?.wmsLayer) return [];

    const metric = find(metrics, { id: activeLayer.metricId });
    if (!metric) return [];

    const value = {
      metric,
      wmsLayer: activeLayer.wmsLayer,
    };
    return [value];
  }, [activeLayer, metrics]);

  // fetch initial data
  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setModels(initialData.models);
      setLayers(initialData.maps);
      setMetrics(initialData.metrics);
    });
  }, []);

  // update legend based on active layer and min/max selection
  useEffect(() => {
    if (!activeLayer?.layerName) return;

    const { min, max } = legendMinMax;
    getLegend(activeLayer.layerName, min, max).then((legend) => {
      setLegend(legend);
    });
  }, [activeLayer, legendMinMax]);

  function handleLayerSelect(model: Model, metricId: number, year: number) {
    // Only persist these selections on 'submit'. The user must click 'load before we load the layer
    const metric = metrics.find((metric) => metric.id === metricId);

    if (metric) {
      dispatch(setLegendMinMax(null));
    }
    dispatch(
      setHistoricalMapsSelection({
        ...formData,
        model,
        metricId,
        year,
      })
    );
  }

  function handleMapClick(event: LeafletMouseEvent) {
    if (isDrawing.value) return;
    const mapEventData = getLeafletEventData(event);
    setRasterCellData(mapEventData);
  }

  // render the component
  return render();

  function render() {
    return (
      <>
        <div className={styles.mapContainer}>
          <Map
            zoom={position.zoom}
            lat={position.lat}
            lng={position.lng}
            onChange={setMapPosition}
            onClick={handleMapClick}
          >
            <MapBounds onChange={(bounds) => setBounds(bounds)} />

            {baselayers.map((layer, i) => (
              <TileLayer key={layer.key} url={layer.url} attribution="" />
            ))}

            {activeLayer && legendMinMax && (
              <WMSTileLayer
                {...activeLayer.wmsLayer!}
                sld={getLegendSldUrl(legendMinMax, activeLayer)}
              />
            )}

            {renderRasterCellPopup()}

            <MapControlGroup position="BOTTOM_RIGHT">
              <DrawControl
                drawing={drawing}
                onChange={(drawing) => dispatch(setDrawing(drawing))}
                onDrawStart={() => isDrawing.setTrue()}
                onDrawStop={() => isDrawing.setFalse()}
              />

              <LeafletDisableClickPropagation>
                <MapControlButton onClick={() => setMapZoom('in')}>
                  <Icon name="plus" />
                </MapControlButton>
              </LeafletDisableClickPropagation>
              <LeafletDisableClickPropagation>
                <MapControlButton onClick={() => setMapZoom('out')}>
                  <Icon name="minus" />
                </MapControlButton>
              </LeafletDisableClickPropagation>
            </MapControlGroup>
            {activeLayer && <LayerCreatedAt layer={activeLayer} />}
          </Map>

          <MapControlGroup position="TOP_LEFT">
            <MapControl>
              <SelectionGroup>
                <LayerSelection
                  selectionState={formData}
                  layers={layers}
                  models={models}
                  metrics={metrics}
                  onSubmit={handleLayerSelect}
                />
              </SelectionGroup>
            </MapControl>
          </MapControlGroup>

          <MapControlGroup position="TOP_RIGHT">
            {renderActiveLayerDetails()}
          </MapControlGroup>

          <MapControlGroup
            position="BOTTOM_LEFT"
            css={css`
              width: 100%;
              flex-direction: row;
              align-items: flex-start;
            `}
          >
            <div className={styles.mapControlGroupRow}>
              {renderLegend()}
              {activeLayer && (
                <MapControl>
                  <GraphContainer
                    layers={graphLayers}
                    closeGraph={() => dispatch(setDrawing(null))}
                  />
                </MapControl>
              )}
            </div>
          </MapControlGroup>
        </div>
      </>
    );
  }

  function renderActiveLayerDetails() {
    // only render active layer details when an active layer present
    if (!activeLayer) return null;

    return (
      <MapControl className={styles.activeLayerPanel}>
        {activeModel && (
          <div className={styles.activeLayerItem}>
            <div className={styles.activeLayerItemKey}>{t('model')}</div>
            <div className={styles.activeLayerItemValue}>
              {activeModel.name}
            </div>
          </div>
        )}
        {activeMetric && (
          <div className={styles.activeLayerItem}>
            <div className={styles.activeLayerItemKey}>{t('metric')}</div>
            <div className={styles.activeLayerItemValue}>
              {activeMetric.quantityDescription}
              {' - '}
              {t(`aggregationDescription.${activeMetric.aggregationKey}`)}
            </div>
          </div>
        )}

        {activeYear && (
          <div className={styles.activeLayerItem}>
            <div className={styles.activeLayerItemKey}>{t('year')}</div>
            <div className={styles.activeLayerItemValue}>{activeYear}</div>
          </div>
        )}
      </MapControl>
    );
  }

  function renderLegend() {
    if (!activeMetric) return null;

    return (
      <LegendContainer
        dynamicCustomize
        customMinMax={legendMinMaxSelection}
        metric={activeMetric}
        layer={activeLayer}
        bounds={bounds}
        legend={legend}
      />
    );
  }

  function renderRasterCellPopup() {
    if (!rasterCellData) return null;
    if (!legend) return null;
    const { latlng } = rasterCellData;

    return (
      <RasterCellPopup
        key={latlng.toString()}
        metricRasters={metricRasterValues}
        mapEventData={rasterCellData}
        legend={legend}
      />
    );
  }
};

interface InitialData {
  models: Model[];
  metrics: Metric[];
  maps: LayerData[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const models = await getHistoricalModels();
  const maps = await getHistoricalMaps();
  const metrics = await getHistoricalMetrics();
  return { models, metrics, maps };
}
