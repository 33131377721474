import styled from 'styled-components';
import { em } from 'polished';
import variables from './../styles/core/variables.scss';

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-family: ${variables.fontFamilyAvenirBlack};
  font-weight: normal;
  font-size: ${em(28, variables.baseFontSize)};
  color: #000000;
`;

export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  font-family: ${variables.fontFamilyAvenirBlack};
  font-weight: normal;
  font-size: ${em(18, variables.baseFontSize)};
  color: #000000;
`;

export const H3 = styled.h3`
  font-family: ${variables.fontFamilyAvenirBlack};
  font-weight: normal;
  font-size: ${em(13, variables.baseFontSize)};
  color: #000000;
`;

export const Paragraph = styled.p``;
