import { LayerData, Model, Station } from '../../types';
import { Drawing } from '../../components/draw-control';

export interface NrtMapsSelection {
  metricId: number | null;
}

export interface NrtAnimationSelection {
  metricId: number | null;
  dateRange: { from: Date; to: Date };
}

export interface ForecastMapsSelection {
  metricId: number | null;
  layer: LayerData | null;
}

export interface ForecastDashboardOverviewSelection {
  station: Station | null;
  metricId: number | null;
  basetime: Date | null;
}

export interface ForecastDashboardValidationSelection {
  station: Station | null;
  metricId: number | null;
  horizon: number | null;
  dateRange: { from: Date; to: Date };
}

export interface HistoricalMapsSelection {
  model: Model | null;
  metricId: number | null;
  year: number | null;
  layer: LayerData | null;
}

export interface SelectionState {
  legend: {
    min: number;
    max: number;
  } | null;
  nrtMaps: NrtMapsSelection;
  nrtAnimation: NrtAnimationSelection;
  forecastMaps: ForecastMapsSelection;
  forecastDashboardOverview: ForecastDashboardOverviewSelection;
  forecastDashboardValiation: ForecastDashboardValidationSelection;
  historicalMaps: HistoricalMapsSelection;
  drawing: Drawing | null;
}

export const SET_LEGEND_MINMAX = 'SET_LEGEND_MINMAX';
export const SET_NRT_MAPS_SELECTION = 'SET_NRT_MAPS_SELECTION';
export const SET_NRT_ANIMATION_SELECTION = 'SET_NRT_ANIMATION_SELECTION';
export const SET_FORECAST_MAPS_SELECTION = 'SET_FORECAST_MAPS_SELECTION';
export const SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION =
  'SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION';
export const SET_FORECAST_DASHBOARD_VALIDATION_SELECTION =
  'SET_FORECAST_DASHBOARD_VALIDATION_SELECTION';
export const SET_HISTORICAL_MAPS_SELECTION = 'SET_HISTORICAL_MAPS_SELECTION';
export const SET_DRAWING = 'SET_DRAWING';

interface SetLegendMinMax {
  type: typeof SET_LEGEND_MINMAX;
  payload: { min: number; max: number } | null;
}

interface SetNrtMapsSelectionAction {
  type: typeof SET_NRT_MAPS_SELECTION;
  payload: NrtMapsSelection;
}

interface SetNrtAnimationSelectionAction {
  type: typeof SET_NRT_ANIMATION_SELECTION;
  payload: NrtAnimationSelection;
}

interface SetForecastMapsSelectionAction {
  type: typeof SET_FORECAST_MAPS_SELECTION;
  payload: ForecastMapsSelection;
}

interface SetForecastDashboardOverviewSelectionAction {
  type: typeof SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION;
  payload: ForecastDashboardOverviewSelection;
}

interface SetForecastDashboardValidationSelectionAction {
  type: typeof SET_FORECAST_DASHBOARD_VALIDATION_SELECTION;
  payload: ForecastDashboardValidationSelection;
}

interface SetHistoricalMapsSelectionAction {
  type: typeof SET_HISTORICAL_MAPS_SELECTION;
  payload: HistoricalMapsSelection;
}

interface SetDrawingType {
  type: typeof SET_DRAWING;
  payload: Drawing | null;
}

export type SelectionActionTypes =
  | SetNrtMapsSelectionAction
  | SetLegendMinMax
  | SetNrtAnimationSelectionAction
  | SetForecastMapsSelectionAction
  | SetForecastDashboardOverviewSelectionAction
  | SetForecastDashboardValidationSelectionAction
  | SetHistoricalMapsSelectionAction
  | SetDrawingType;
