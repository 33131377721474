import styled, { css } from 'styled-components';
import variables from './../styles/core/variables.scss';
import { em } from 'polished';

export const Form = styled.form``;

export const FormGroup = styled.div<{ invalid?: boolean }>`
  position: relative;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  &:focus-within {
    .label {
      color: #33a3dc;
    }
    .inputField {
      box-shadow: 3px 3px 6px #00000029;
    }
  }

  ${props =>
    props.invalid &&
    css`
      color: ${variables.colorInvalid};
      background-color: ${variables.colorInvalid};

      ${Label} {
        color: ${variables.colorInvalid};
        color: green;
      }
    `}
`;

export const Label = styled.label<{ invalid?: boolean }>`
  padding-bottom: 10px;
  font-family: ${variables.fontFamilyAvenirRoman};
  font-size: ${em(11, variables.baseFontSize)};
  color: #474747;
  text-transform: uppercase;

  ${props =>
    props.invalid &&
    css`
      color: ${variables.colorInvalid};
      color: blue;
    `}
`;

export const InputField = styled.input<{ invalid?: boolean }>`
  background-color: white;
  padding: 20px 20px 19px;
  width: 100%;
  color: black;
  font-family: ${variables.fontFamilyAvenirRoman};
  font-size: ${em(11, variables.baseFontSize)};
  border: 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  outline: none;
  transition: all 300ms;

  &::placeholder {
    color: #b1b1b1;
  }

  ${props =>
    props.invalid &&
    css`
      color: $color-invalid;
      border-color: $color-invalid;

      &::placeholder {
        color: $color-invalid;
      }
    `}
`;

export const InvalidFeedback = styled.div`
  color: ${variables.colorInvalid};
  margin: 0;
  margin-top: 0.25rem;
  padding: 0;
`;
