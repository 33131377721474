import {
  SelectionActionTypes,
  SET_LEGEND_MINMAX,
  SET_NRT_ANIMATION_SELECTION,
  SET_FORECAST_MAPS_SELECTION,
  NrtAnimationSelection,
  ForecastMapsSelection,
  NrtMapsSelection,
  SET_NRT_MAPS_SELECTION,
  HistoricalMapsSelection,
  SET_HISTORICAL_MAPS_SELECTION,
  ForecastDashboardOverviewSelection,
  SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION,
  ForecastDashboardValidationSelection,
  SET_FORECAST_DASHBOARD_VALIDATION_SELECTION,
  SET_DRAWING,
} from './types';
import { Drawing } from '../../components/draw-control';

export function setLegendMinMax(
  payload: { min: number; max: number } | null
): SelectionActionTypes {
  return {
    type: SET_LEGEND_MINMAX,
    payload,
  };
}

export function setNrtMapsSelection(
  data: NrtMapsSelection
): SelectionActionTypes {
  return {
    type: SET_NRT_MAPS_SELECTION,
    payload: data,
  };
}

export function setNrtAnimationFormSelection(
  data: NrtAnimationSelection
): SelectionActionTypes {
  return {
    type: SET_NRT_ANIMATION_SELECTION,
    payload: data,
  };
}

export function setHistoricalMapsSelection(
  data: HistoricalMapsSelection
): SelectionActionTypes {
  return {
    type: SET_HISTORICAL_MAPS_SELECTION,
    payload: data,
  };
}

export function setForecastMapsFormSelection(
  data: ForecastMapsSelection
): SelectionActionTypes {
  return {
    type: SET_FORECAST_MAPS_SELECTION,
    payload: data,
  };
}

export function setForecastDashboardOverviewSelection(
  data: ForecastDashboardOverviewSelection
): SelectionActionTypes {
  return {
    type: SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION,
    payload: data,
  };
}

export function setForecastDashboardValidationSelection(
  data: ForecastDashboardValidationSelection
): SelectionActionTypes {
  return {
    type: SET_FORECAST_DASHBOARD_VALIDATION_SELECTION,
    payload: data,
  };
}

export function setDrawing(drawing: Drawing | null): SelectionActionTypes {
  return {
    type: SET_DRAWING,
    payload: drawing,
  };
}
