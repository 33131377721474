import { subDays, startOfDay } from 'date-fns/esm';
import {
  SelectionState,
  SelectionActionTypes,
  SET_LEGEND_MINMAX,
  SET_NRT_ANIMATION_SELECTION,
  SET_FORECAST_MAPS_SELECTION,
  SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION,
  SET_FORECAST_DASHBOARD_VALIDATION_SELECTION,
  SET_NRT_MAPS_SELECTION,
  SET_HISTORICAL_MAPS_SELECTION,
  SET_DRAWING,
} from './types';

export const initialState: SelectionState = {
  legend: null,
  nrtMaps: {
    metricId: null,
  },
  nrtAnimation: {
    metricId: null,
    dateRange: {
      from: subDays(startOfDay(new Date()), 5),
      to: startOfDay(new Date()),
    },
  },
  forecastMaps: {
    metricId: null,
    layer: null,
  },
  forecastDashboardOverview: {
    station: null,
    metricId: null,
    basetime: null,
  },
  forecastDashboardValiation: {
    station: null,
    metricId: null,
    horizon: null,
    dateRange: {
      from: subDays(startOfDay(new Date()), 5),
      to: startOfDay(new Date()),
    },
  },
  historicalMaps: {
    model: null,
    metricId: null,
    layer: null,
    year: null,
  },
  drawing: null,
};

export const selectionReducer = (
  state = initialState,
  action: SelectionActionTypes
): SelectionState => {
  switch (action.type) {
    case SET_LEGEND_MINMAX:
      return {
        ...state,
        legend: action.payload,
      };

    case SET_NRT_MAPS_SELECTION:
      return {
        ...state,
        nrtMaps: action.payload,
      };

    case SET_NRT_ANIMATION_SELECTION:
      return {
        ...state,
        nrtAnimation: action.payload,
      };

    case SET_FORECAST_MAPS_SELECTION:
      return {
        ...state,
        forecastMaps: action.payload,
      };

    case SET_FORECAST_DASHBOARD_OVERVIEW_SELECTION:
      return {
        ...state,
        forecastDashboardOverview: action.payload,
      };

    case SET_FORECAST_DASHBOARD_VALIDATION_SELECTION:
      return {
        ...state,
        forecastDashboardValiation: action.payload,
      };

    case SET_HISTORICAL_MAPS_SELECTION:
      return {
        ...state,
        historicalMaps: action.payload,
      };

    case SET_DRAWING:
      return {
        ...state,
        drawing: action.payload,
      };

    default:
      return state;
  }
};
