import { useEffect } from 'react';
import { useLeafletBounds } from 'use-leaflet';
import { usePrevious } from 'react-hanger';
import { isEqual, noop } from 'lodash-es';

interface Props {
  onChange: (bounds: [[number, number], [number, number]]) => void;
}

const defaultProps = {
  onChange: noop,
};

export const MapBounds = (props: Props) => {
  const bounds = useLeafletBounds();
  const previousBounds = usePrevious(bounds);

  useEffect(() => {
    if (!isEqual(bounds, previousBounds)) {
      if (bounds) props.onChange(bounds);
    }
  }, [props, previousBounds, bounds]);

  return null;
};
MapBounds.defaultProps = defaultProps;
