import { createStore, applyMiddleware, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer, createMigrate, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../redux/root-reducer'; // the value from combineReducers
import migrations from './migrations';

const middleware: Middleware[] = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    collapsed: true,
    logger: console,
  });
  middleware.push(logger);
}

/**
 * Revive date types from persisted (and stringified) JSON
 *   more details: https://github.com/rt2zz/redux-persist/issues/82
 */
const replacer = (key: any, value: any) =>
  value instanceof Date ? value.toISOString() : value;

const reviver = (key: any, value: any) =>
  typeof value === 'string' &&
  value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    ? new Date(value)
    : value;

export const encode = (toDehydrate: any) =>
  JSON.stringify(toDehydrate, replacer);
export const decode = (toRehydrate: any) => JSON.parse(toRehydrate, reviver);
/** end */

const persistConfig = {
  key: `${window._env_.REACT_APP_NAME}`,
  storage,
  version: 3,
  whitelist: ['auth', 'map', 'selection'],
  migrate: createMigrate(migrations, { debug: true }),
  transforms: [createTransform(encode, decode)],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export { store };
