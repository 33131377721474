import { range, round, minBy, maxBy, times, constant } from 'lodash-es';
import { ColorMap, ColorMapEntry } from 'marvin-ui-kit';
import { interpolateRgb } from 'd3-interpolate';
import { APILegend } from '../types';

export function colorMapWithCustomRange(
  colorMap: ColorMap,
  min: number,
  max: number
): ColorMap {
  const length = 10;
  let customRange = [...range(min, max, (max - min) / length), max];
  if (min === null || max === null) {
    customRange = [...times(length, constant(0)), max || 0];
  }

  return {
    ...colorMap,
    entries: colorMap.entries.map((entry, i) => ({
      ...entry,
      quantity: round(customRange[i], 2),
    })),
  };
}

/**
 * Define color for value based on Legend
 * @param legend
 * @param value - absolute value to define color for
 * @param type - ramp or interval, based on Geoserver sld definition
 * @returns color - string
 */
export function interpolateColor(
  legend: APILegend,
  value: number,
  type: 'ramp' | 'interval' = 'interval'
) {
  const lowerBound = maxBy(
    legend.colorMap.entries.filter((entry) => entry.quantity <= value),
    'quantity'
  );
  const upperBound = minBy(
    legend.colorMap.entries.filter((entry) => entry.quantity > value),
    'quantity'
  );

  const lowerBoundValue = lowerBound || upperBound;
  const upperBoundValue = upperBound || lowerBound;

  if (!lowerBoundValue || !upperBoundValue) {
    console.warn("Can't interpolate when missing lower or upper bound value");
    return 'pink';
  }

  if (type === 'ramp') {
    return getRampValue(value, lowerBoundValue, upperBoundValue);
  }

  if (type === 'interval') {
    return lowerBoundValue.color;
  }

  return 'pink';
}

function getRampValue(
  value: number,
  lower: ColorMapEntry,
  upper: ColorMapEntry
) {
  const getRelativeValue = () => {
    const relativeValue =
      value - lower.quantity / (upper.quantity - lower.quantity);

    if (relativeValue < 0) return 0;
    if (relativeValue > 1) return 1;
    return relativeValue;
  };

  const relativeValue = getRelativeValue();
  const result = interpolateRgb(lower.color, upper.color)(relativeValue);
  return result;
}
