import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { getYear, differenceInDays } from 'date-fns/esm';
import styles from './styles.module.scss';
import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { localeUtils } from '../../utils/react-day-picker.utils';

interface DateRange {
  from: Date;
  to: Date;
}

interface Props {
  dateRange: DateRange;
  datesWithData: Date[];
  maxRange: number;
  onRangeSelect: (dateRange: DateRange) => void;
  onYearChange: (year: number) => void;
}

export const DateRangePicker = (props: Props) => {
  const { dateRange, datesWithData, maxRange } = props;
  const { i18n } = useTranslation();

  const [selectedYear, setSelectedYear] = useState<number>(
    getYear(props.dateRange.from)
  );

  const modifiers = {
    start: dateRange.from,
    end: dateRange.to,
    withData: datesWithData,
  };

  function defineNewRange(day: Date) {
    // select at max a  limited date range
    // without a limit, the animation strip control would fail to render
    const diffDaysFrom = Math.abs(differenceInDays(day, dateRange.from));
    const diffDaysTo = Math.abs(differenceInDays(day, dateRange.to));

    const noMaxRange = maxRange === -1;
    const insideMaxRange = diffDaysTo < maxRange && diffDaysFrom < maxRange;

    if (noMaxRange || insideMaxRange) {
      return DateUtils.addDayToRange(day, dateRange);
    } else {
      // reset range
      return { from: day, to: day };
    }
  }
  function handleDayClick(day: Date) {
    props.onRangeSelect(defineNewRange(day));
  }

  function handleMonthChange(month: Date) {
    const year = getYear(month);
    if (year !== selectedYear) handleYearChange(year);
  }

  function handleYearChange(year: number) {
    setSelectedYear(year);
    props.onYearChange(year);
  }

  return (
    <DayPicker
      showOutsideDays
      className={styles.dateRange}
      initialMonth={dateRange.from}
      selectedDays={[dateRange.from, dateRange]}
      modifiers={modifiers}
      onDayClick={handleDayClick}
      onMonthChange={handleMonthChange}
      locale={i18n.language}
      localeUtils={localeUtils}
    />
  );
};

DateRangePicker.defaultProps = {
  datesWithData: [],
  maxRange: -1,
  onYearChange: noop,
};
