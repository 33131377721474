import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import classnames from 'classnames';

import * as initActions from '../../redux/init/actions';
import { Preloader } from '../../components/preloader';
import styles from './styles.module.scss';
import { NrtMapsContainer } from '../nrt-maps.container';
import { HeaderContainer } from '../header.container.tsx';
import { NrtAnimationContainer } from '../nrt-animation.container';
import { HistoricalMapsContainer } from '../historical-maps.container';
import { MainNavigation } from '../../components/main-navigation';
import { useBoolean } from 'react-hanger';
import { ForecastMapsContainer } from '../forecast-maps.container';
import { ForecastDashboard } from '../forecast-dashboard.container';

interface Props {}

export const AppContainer = (props: Props) => {
  const isInitialized = useBoolean(false);
  const isError = useBoolean(false);
  const [error, setError] = useState<Error>(new Error());
  const dispatch = useDispatch();

  useEffect(
    function loadInitialData() {
      // TODO: the dispatch return valdue should be better typed
      dispatch<any>(initActions.load())
        .then(() => {
          isInitialized.setTrue();
        })
        .catch((ex: Error) => {
          isError.setTrue();
          setError(ex);
        });
    },
    [dispatch, isError, isInitialized]
  );

  // render the component
  return render();

  function render() {
    return (
      // TODO: think if the preloader should also act as blocker for rendering children
      <Preloader isLoading={!isInitialized.value}>
        <React.Fragment>
          {isError.value && renderError()}
          {isInitialized.value && renderSuccess()}
        </React.Fragment>
      </Preloader>
    );
  }

  function renderError() {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          Error while loading...
          <div className={styles.errorDetail}>{error.toString()}</div>
        </div>
      </div>
    );
  }
  function renderSuccess() {
    const cxContainer = classnames(styles.container, {});
    return (
      <div className={cxContainer}>
        <MainNavigation />

        <div className={styles.content}>
          <HeaderContainer />
          <div className={styles.main}>
            <Switch>
              <Route path="/nrt/maps" component={NrtMapsContainer} />
              <Route path="/nrt/animation" component={NrtAnimationContainer} />
              <Route path="/forecast/maps" component={ForecastMapsContainer} />
              <Route path="/forecast/dashboard" component={ForecastDashboard} />
              <Route
                path="/historical/maps"
                component={HistoricalMapsContainer}
              />
              <Redirect to="/nrt/maps" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
};
