import React, { useState, useEffect } from 'react';
import { Popup, WMSTileLayerProps } from 'react-leaflet';
import { isEmpty } from 'lodash-es';

import { Metric, APILegend } from '../../types';
import { interpolateColor } from '../../utils/legend.utils';
import { MetricValueItem } from './../metric-value-item';
import { MapEventData } from '../../utils/leaflet.utils';
import { getFeatureInfo } from '../../services/geoserver.service';
import { MetricMeasurementItems } from '../metric-measurement-items';
import { formatLatLng } from '../../utils/format.utils';
import {
  NoFeaturesForPositionError,
  NoFeaturesForCellError,
} from '../../errors';

export interface MetricRaster {
  metric: Metric;
  wmsLayer: WMSTileLayerProps;
}

interface Props {
  metricRasters: MetricRaster[];
  mapEventData: MapEventData;
  legend: APILegend;
}

export const RasterCellPopup = React.memo((props: Props) => {
  const { latlng } = props.mapEventData;

  const colorInterpolator = (legend: APILegend) => (value: number) =>
    interpolateColor(legend, value);

  const title = formatLatLng(latlng);

  return (
    <Popup position={latlng}>
      <MetricMeasurementItems
        header={title}
        measurements={props.metricRasters.map((metricRaster, i) => (
          <MetricRasterItem
            key={i}
            mapEventData={props.mapEventData}
            metricRaster={metricRaster}
            colorInterpolator={colorInterpolator(props.legend)}
          />
        ))}
      />
    </Popup>
  );
});

interface MetricRasterItemProps {
  metricRaster: MetricRaster;
  mapEventData: MapEventData;
  colorInterpolator: (value: number) => string;
}

export const MetricRasterItem = (props: MetricRasterItemProps) => {
  const { metricRaster, mapEventData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const featureInfo = await getFeatureInfo(
          mapEventData,
          metricRaster.wmsLayer
        );

        setIsLoading(false);

        if (!isEmpty(featureInfo.features)) {
          if (featureInfo.features[0].properties) {
            const value = featureInfo.features[0].properties.GRAY_INDEX;
            return setValue(value);
          } else {
            throw NoFeaturesForCellError(mapEventData);
          }
        } else {
          setValue(null);
          throw NoFeaturesForPositionError(mapEventData);
        }
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, [mapEventData, metricRaster]);

  // render the component
  return render();

  function render() {
    return (
      <MetricValueItem
        value={value}
        colorInterpolator={props.colorInterpolator}
        quantityName={metricRaster.metric.quantityName}
        unit={metricRaster.metric.unit}
        isLoading={isLoading}
      />
    );
  }
};
