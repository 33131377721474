import { useLayoutEffect, useState, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface Dimensions {
  x: number;
  y: number;
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

// Export hook
export function useDimensions(): {
  ref: (node: HTMLElement | null) => void;
  dimensions: Dimensions;
} {
  const [node, setNode] = useState<null | HTMLElement>(null);
  const ref = useCallback((newNode: HTMLElement | null) => {
    setNode(newNode);
  }, []);

  // Keep track of measurements
  const [dimensions, setDimensions] = useState<Dimensions>({
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  });

  // Define measure function
  const measure = useCallback((innerNode: HTMLElement) => {
    const rect = innerNode.getBoundingClientRect();
    setDimensions({
      x: rect.left,
      y: rect.top,
      left: rect.left,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      width: rect.width,
      height: rect.height,
    });
  }, []);

  useLayoutEffect(() => {
    if (!node) {
      return;
    }

    // Set initial measurements
    measure(node);

    // Observe resizing of element
    const resizeObserver = new ResizeObserver(() => {
      measure(node);
    });

    resizeObserver.observe(node);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [node, measure]);

  return {
    ref,
    dimensions,
  };
}

// import { useState, useCallback, useLayoutEffect } from 'react';

// export interface DimensionObject {
//   width: number;
//   height: number;
//   top: number;
//   left: number;
//   x: number;
//   y: number;
//   right: number;
//   bottom: number;
// }

// export type UseDimensionsHook = [
//   (node: HTMLElement) => void,
//   {} | DimensionObject,
//   HTMLElement
// ];

// export interface UseDimensionsArgs {
//   liveMeasure?: boolean;
// }

// function getDimensionObject(node: HTMLElement): DimensionObject {
//   const rect = node.getBoundingClientRect();

//   return {
//     width: rect.width,
//     height: rect.height,
//     top: rect.x || rect.top,
//     left: rect.y || rect.left,
//     x: rect.x || rect.left,
//     y: rect.y || rect.top,
//     right: rect.right,
//     bottom: rect.bottom,
//   };
// }

// export function useDimensions({
//   liveMeasure = true,
// }: UseDimensionsArgs = {}): UseDimensionsHook {
//   const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
//   const [node, setNode] = useState<HTMLElement | null>(null);

//   const ref = useCallback(node => {
//     setNode(node);
//   }, []);

//   useLayoutEffect(() => {
//     if (node) {
//       const measure = () =>
//         window.requestAnimationFrame(() =>
//           setDimensions(getDimensionObject(node))
//         );
//       measure();

//       if (liveMeasure) {
//         window.addEventListener('resize', measure);
//         window.addEventListener('scroll', measure);

//         return () => {
//           window.removeEventListener('resize', measure);
//           window.removeEventListener('scroll', measure);
//         };
//       }
//     }
//   }, [node]);

//   return [ref, dimensions!, node!];
// }
