// import React from 'react';
import styled, { css } from 'styled-components';
import variables from './../styles/core/variables.scss';

interface Props {
  disabled?: boolean;
}

export const Button = styled.button<Props>`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 38px;
  padding: 0 28px;
  cursor: pointer;

  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${variables.fontFamilyAvenirBlack};
  // TODO: refactor this into function and em
  font-size: 11px;

  border: 0;
  background: transparent linear-gradient(112deg, #33a3dc 0%, #547ccc 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  transition: all 300ms;

  &:hover {
    box-shadow: 0px 3px 6px #00000000;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ButtonSecondary = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 14px 10px;
`;
