import { GuppyLayer, LayerData, APILegend } from '../types';
import { CRS } from 'leaflet';
import { guppy } from './guppy.service';
import axios from 'axios';

export async function getLegend(
  name: string,
  min: number,
  max: number
): Promise<APILegend> {
  const response = await axios.get<{ data: APILegend }>(`/api/legend`, {
    params: { name, min, max },
  });

  return response.data.data;
}

export async function getLegendMinMax(layer: GuppyLayer, wkt: string) {
  const body = {
    geometry: wkt,
    srs: CRS.EPSG4326.code!,
    resolution: 'auto' as 'auto',
  };

  const stats = await guppy.getRasterStats(layer.uuid, body);

  return { min: stats.min, max: stats.max };
}

export function getLegendSldUrl(
  { min, max }: { min: number; max: number },
  layer: LayerData
): string {
  return `${window._env_.REACT_APP_API_SERVICE_URL}/legendSld?min=${min}&max=${max}&name=${layer.wmsLayer?.layers}`;
}
