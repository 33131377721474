import axios from 'axios';
import { startOfDay, endOfDay } from 'date-fns';
import { getUnixTime } from 'date-fns/esm';
import { sortBy } from 'lodash-es';
import { AnimationImage } from '../types';

export async function getImages(
  metricId: number,
  fromDate: Date,
  tillDate: Date
): Promise<AnimationImage[]> {
  const response = await axios.get(`/api/nrt/images`, {
    params: {
      metricId: metricId,
      fromDate: getUnixTime(startOfDay(fromDate)),
      tillDate: getUnixTime(endOfDay(tillDate)),
    },
  });
  return sortBy(response.data.data, ['mapDate']);
}

export async function getDates(
  metricId: number,
  year: number
): Promise<number[]> {
  try {
    const response = await axios.get(`/api/nrt-animation/dates`, {
      params: { year, metricId },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
}
