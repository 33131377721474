import React, { useEffect, useMemo, useState } from 'react';

import { AnimationImage, APILegend, Metric } from '../../types';
import * as nrtAnimationService from '../../services/nrt-animation.service';
import { AnimationStrip } from '../../components/animation-strip';
import styles from './styles.module.scss';
import { AnimationStripFrameControl } from '../../components/animation-strip-frame-control';
import { NrtAnimationForm } from './nrt-animation-form';
import { AnimationControlButtons } from '../../components/animation-control-buttons';
import { useSelector, useDispatch } from 'react-redux';
import { getNrtAnimationFormSelection } from '../../selectors';
import { NrtAnimationSelection } from '../../redux/selection/types';
import { setNrtAnimationFormSelection } from '../../redux/selection/actions';
import { getLegend } from '../../services/legend.service';
import { getNrtMetrics } from '../../services/metric.service';

interface Props {}

export const NrtAnimationContainer = (props: Props) => {
  const dispatch = useDispatch();
  const formData = useSelector(getNrtAnimationFormSelection);

  const [legend, setLegend] = useState<APILegend | null>(null);
  const [images, setImages] = useState<AnimationImage[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [activeAnimationIndex, setActiveAnimationIndex] = useState(0);

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );

  useEffect(init, []);

  // update legend based on active layer and min/max selection
  useEffect(() => {
    if (!activeMetric) return;

    const { min, max } = activeMetric;
    getLegend('animation', min, max).then((legend) => {
      setLegend(legend);
    });
  }, [activeMetric]);

  function init() {
    loadImages(formData);

    fetchInitialData().then((initialData) => {
      setMetrics(initialData.metrics);
    });
  }

  function handleFormSubmit(formData: NrtAnimationSelection) {
    loadImages(formData);
    dispatch(setNrtAnimationFormSelection(formData));
  }

  async function loadImages(formData: NrtAnimationSelection) {
    const { metricId, dateRange } = formData;
    if (!metricId || !dateRange) {
      console.warn('Can not fetch images with incomplete params');
      return;
    }

    setImages([]);

    const images = await nrtAnimationService.getImages(
      metricId,
      dateRange.from,
      dateRange.to
    );

    setImages(images);
  }

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <NrtAnimationForm onSubmit={handleFormSubmit} />
        </div>
        <div className={styles.body}>
          {renderAnimationStrip()}

          <div className={styles.animationControlButtons}>
            <AnimationControlButtons
              images={images}
              activeIndex={activeAnimationIndex}
              onChange={(index: number) => setActiveAnimationIndex(index)}
            />
          </div>

          <div className={styles.framesControl}>
            <AnimationStripFrameControl
              images={images}
              activeIndex={activeAnimationIndex}
              onChange={(index: number) => setActiveAnimationIndex(index)}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderAnimationStrip() {
    if (!activeMetric) return null;
    if (!legend) return null;

    return (
      <div className={styles.animationStrip}>
        <AnimationStrip
          metric={activeMetric}
          images={images}
          activeIndex={activeAnimationIndex}
          legend={legend}
        />
      </div>
    );
  }
};

interface InitialData {
  metrics: Metric[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const metrics = await getNrtMetrics();
  return { metrics };
}
