import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import {
  SubNavigation,
  SubNavigationItem,
} from '../../components/sub-navigation';
import { ForecastDashboardOverview } from './forecast-dashboard-overview.container';
import { ForecastDashboardValidation } from './forecast-dashboard-validation.container';

interface Props {}

export function ForecastDashboard(props: Props) {
  const { t } = useTranslation();

  return render();

  function render() {
    return (
      <div className={styles.container}>
        <SubNavigation>
          <SubNavigationItem to={'/forecast/dashboard/overview'}>
            {t('dashboard.navigation.overview')}
          </SubNavigationItem>
          <SubNavigationItem to={'/forecast/dashboard/validation'}>
            {t('dashboard.navigation.validation')}
          </SubNavigationItem>
        </SubNavigation>

        <div className={styles.content}>
          <Switch>
            <Route
              path="/forecast/dashboard/overview"
              component={ForecastDashboardOverview}
            />
            <Route
              path="/forecast/dashboard/validation"
              component={ForecastDashboardValidation}
            />
            <Redirect to="/forecast/dashboard/overview" />
          </Switch>
        </div>
      </div>
    );
  }
}
