import React, { useState, useEffect } from 'react';
import { FeatureGroup, GeoJSON, useLeaflet } from 'react-leaflet';
import * as L from 'leaflet';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
// @ts-ignore: after PR is merged, typings will be available: https://github.com/alex3165/react-leaflet-draw/pull/72
import { EditControl } from 'react-leaflet-draw';
import { Feature } from 'geojson';
import { MapControlButton } from 'marvin-ui-kit';
import { useBoolean } from 'react-hanger';

import { LeafletDisableClickPropagation } from '../../components/leaflet-disable-click-propagation';
import Icon from '../icon';
import styles from './styles.module.scss';

export interface Drawing {
  id: string;
  layer: Feature | null;
  layerType: L.DrawEvents.Created['layerType'];
}
interface Props {
  drawing: Drawing | null;
  onChange: (drawing: Drawing) => void;
  onDrawStart: () => void;
  onDrawStop: () => void;
}

export const DrawControl = (props: Props) => {
  const style = {
    fillColor: 'black',
    color: 'black',
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    translationDraw(t);
  }, [t, i18n.language]);

  const leaflet = useLeaflet();
  const [drawMode, setDrawMode] = useState<string | null>(null);
  const [drawControl, setDrawControl] = useState<any | null>(null);
  const isDrawingPolyline = useBoolean(false);

  function _onCreated(e: L.DrawEvents.Created) {
    const { layer, layerType } = e;

    const { layerContainer } = leaflet;

    if (layerContainer) {
      // remove from global layers list
      layerContainer.removeLayer(layer);

      // construct drawing object
      const drawing = {
        id: `drawing-${new Date().getTime()}`,
        layer: layer.toGeoJSON(),
        layerType: layerType,
      };

      props.onChange(drawing);
    }
  }

  function _onMounted(drawControl: any) {
    setDrawControl(drawControl);
  }

  function handleDrawPolyline() {
    isDrawingPolyline.toggle();

    if (isDrawingPolyline.value) {
      drawControl._toolbars.draw._modes.polyline.handler.disable();
    } else {
      drawControl._toolbars.draw._modes.polyline.handler.enable();
    }
  }

  function handleDrawStart(e: L.DrawEvents.DrawStart) {
    isDrawingPolyline.setTrue();
    setDrawMode(e.layerType);
    props.onDrawStart();
  }

  function handleDrawStop(e: L.DrawEvents.DrawStart) {
    isDrawingPolyline.setFalse();
    setDrawMode(null);
    props.onDrawStop();
  }

  return render();

  function render() {
    const { drawing } = props;

    return (
      <>
        <LeafletDisableClickPropagation>
          <MapControlButton
            size="medium"
            isactive={drawMode === 'polyline'}
            onClick={handleDrawPolyline}
          >
            <Icon name="ruler" fill="#9BA1AE" size={20} />
          </MapControlButton>
        </LeafletDisableClickPropagation>

        <FeatureGroup className={styles.leafletDraw}>
          <EditControl
            position="topleft"
            onCreated={_onCreated}
            onMounted={_onMounted}
            onDrawStart={handleDrawStart}
            onDrawStop={handleDrawStop}
            draw={{
              polyline: {
                shapeOptions: style,
                tooltip: { start: 'Starten maar' },
              },
              polygon: false,
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
            }}
          />
          {/* TODO: should this component be responsible for visualising the drawn GeoJSON? */}
          {drawing && drawing?.layer && (
            <GeoJSON key={drawing.id} data={drawing.layer} style={style} />
          )}
        </FeatureGroup>
      </>
    );
  }
};

function translationDraw(t: TFunction) {
  L.drawLocal.draw.handlers.polyline.tooltip.start = t('draw.start');
  L.drawLocal.draw.handlers.polyline.tooltip.cont = t('draw.continue');
  L.drawLocal.draw.handlers.polyline.tooltip.end = t('draw.finish');
}
