// TODO: properly think through this action vvv
export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

interface SetAuthTokensAction {
  type: typeof SET_AUTH_TOKENS;
  payload: {
    accessToken: string | null;
    refreshToken: string | null;
  };
}

export type AuthActionTypes = SetAuthTokensAction;
