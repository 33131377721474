import React, { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import cx from 'classnames';
import * as authService from '../../services/auth.service';
import { ServerError } from '../../types';
import {
  InvalidFeedback,
  Label,
  FormGroup,
  InputField,
} from '../../components/form';
import styles from './styles.module.scss';
import { Button, ButtonSecondary } from '../../components/button';
import { H1, H2 } from '../../components/typography';
import { useTranslation } from 'react-i18next';

interface Props {}

export const ForgotPasswordContainer = (props: Props) => {
  const { t } = useTranslation();
  const [serverErrors, setServerErrors] = useState<ServerError[]>([]);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t('form.emailRequired')),
  });

  return (
    <div className={styles.container}>
      <H1>{t('forgotPassword.title')}</H1>
      <H2>{t('forgotPassword.subtitle')}</H2>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={async (values, actions) => {
          setServerErrors([]);

          try {
            await authService.forgotPassword(values.email);
          } catch (err) {
            setServerErrors([err]);
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, getFieldProps, handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <FormGroup>
              <Label htmlFor="email">{t('forgotPassword.form.email')}</Label>
              <InputField
                {...getFieldProps('email')}
                id="email"
                placeholder={t('forgotPassword.form.emailPlaceholder')}
              />
              <InvalidFeedback>
                <ErrorMessage name="email" />
              </InvalidFeedback>
            </FormGroup>

            {!isEmpty(serverErrors) && (
              <FormGroup>
                <div className={cx(styles.alert, styles.alertDanger)}>
                  {serverErrors.map(error => {
                    return error.message;
                  })}
                </div>
              </FormGroup>
            )}

            <FormGroup className={styles.formActions}>
              <Button type="submit" disabled={isSubmitting}>
                {t('forgotPassword.actions.send')}
              </Button>

              <ButtonSecondary as={Link} to="/login">
                {t('forgotPassword.actions.cancel')}
              </ButtonSecondary>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  );
};
