import React from 'react';
import atmosysLogo from './../../assets/images/logo-atmosys.png';
import styles from './styles.module.scss';

interface Props {
  // TODO: better define children type
  children: React.ReactElement;
}

export const AuthLayout = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.applicationLogo}>
          <img src={atmosysLogo} alt="Logo atmosys" />
        </div>
        {props.children}
      </div>
    </div>
  );
};
