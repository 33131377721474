import * as turf from '@turf/helpers';

export function latLngBoundsToGeometry([[south, west], [north, east]]: [
  [number, number],
  [number, number]
]): turf.Feature | null {
  if (south > north) return null;

  return turf.polygon([
    [[west, north], [east, north], [east, south], [west, south], [west, north]],
  ]);
}
