import { Auth } from 'marvin-auth-kit';
import axios from 'axios';

export const auth = new Auth({
  url: '/auth', // same domain
  tenant: 'default',
});

// TODO: this will be replaced in the marvin-auth-kit
export async function forgotPassword(email: string): Promise<void> {
  try {
    await axios.post('/auth/forgot_password');
    return;
  } catch (error) {
    throw new Error(error);
  }
}

// TODO: this will be replaced in the marvin-auth-kit
export async function resetPassword(
  token: string,
  password: string
): Promise<void> {
  try {
    await axios.post('/auth/password/reset', {
      token,
      password,
    });
    return;
  } catch (error) {
    throw new Error(error);
  }
}
