import styled from 'styled-components';

export const SelectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px 30px 30px;
  background-color: #fbfbfb;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);
`;

export const SelectionGroupLabel = styled.div`
  text-transform: uppercase;
  padding-bottom: 15px;
`;

export const SelectionGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;

  & + & {
    margin-top: 20px;
  }

  > .select {
    min-width: 100%;
  }
`;
