import { createSelector } from 'reselect';
import { RootState, Baselayer } from '../types';
import { baselayers } from '../config';

export const getMapState = (state: RootState) => state.map;
export const getSelectionState = (state: RootState) => state.selection;
export const getBasedataState = (state: RootState) => state.basedata;

export const getBaselayers = (state: RootState): Baselayer[] => {
  return baselayers;
};

export const getPosition = createSelector([getMapState], map => {
  return map.position;
});

export const getLegendMinMaxSelection = createSelector(
  [getSelectionState],
  selection => selection.legend
);

export const getNrtMapsSelection = createSelector(
  [getSelectionState],
  selection => selection.nrtMaps
);

export const getNrtAnimationFormSelection = createSelector(
  [getSelectionState],
  selection => selection.nrtAnimation
);

export const getForecastMapsSelection = createSelector(
  [getSelectionState],
  selection => selection.forecastMaps
);

export const getForecastDashboardOverviewSelection = createSelector(
  [getSelectionState],
  selection => selection.forecastDashboardOverview
);

export const getForecastDashboardValidationSelection = createSelector(
  [getSelectionState],
  selection => selection.forecastDashboardValiation
);

export const getHistoricalMapsFormSelection = createSelector(
  [getSelectionState],
  selection => selection.historicalMaps
);

export const getDrawing = createSelector([getSelectionState], selection => {
  return selection.drawing;
});
