import styled from 'styled-components';

interface Props {
  color: string;
}

export const ModelCell = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* @include AvenirLight; */
  font-size: 14px;
  color: #212121;

  &:before {
    content: '';
    display: block;
    position: relative;
    width: 43px;
    height: 9px;
    margin-right: 10px;
    background-color: ${props => props.color};
    border-radius: 5px;
  }
`;

ModelCell.defaultProps = {
  color: 'whitesmoke',
};
