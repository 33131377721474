import { format as tzFormat, zonedTimeToUtc } from 'date-fns-tz';
import { isDate } from 'lodash-es';
import formatcoords from 'formatcoords';
import { Metric } from '../types';
import i18n from '../i18n';

export function formatLatLng(position: { lat: number; lng: number }): string {
  return formatcoords(position).format('f', {
    decimalPlaces: 4,
    latLonSeparator: ', ',
  });
}
export function formatMetricName(metric: Metric): string {
  const aggregationName = i18n.t(`aggregationName.${metric.aggregationKey}`);
  return `${metric.quantityName} - ${aggregationName}`;
}

export function formatDate(dateInput: Date | number, pattern: string): string {
  const timeZone = window._env_.REACT_APP_TIMEZONE!;
  const date = isDate(dateInput) ? dateInput : new Date(dateInput);
  return tzFormat(zonedTimeToUtc(date, timeZone), pattern, { timeZone });
}
