import { MapEventData } from './utils/leaflet.utils';

export const UnknownConfigLanguage = (language: string) =>
  new Error(
    `A configured language is not available in the application: ${language}`
  );

export const ServerError = () =>
  new Error('Something went wrong, please contact a system administrator.');

export const NoFeaturesForCellError = (mapEventData: MapEventData) =>
  new Error(
    `No properties for this feature cell: ${mapEventData.latlng.toString()}`
  );

export const NoFeaturesForPositionError = (mapEventData: MapEventData) =>
  new Error(
    `No features found for this position, ${mapEventData.latlng.toString()}`
  );
