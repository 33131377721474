import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LeafletMouseEvent } from 'leaflet';
import { TileLayer, WMSTileLayer } from 'react-leaflet';
import { useBoolean } from 'react-hanger';
import Select from 'react-select';
import {
  MapControlGroup,
  MapControl,
  Map,
  MapControlButton,
} from 'marvin-ui-kit';
import { isEmpty, find, pick, last } from 'lodash-es';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { Metric, Station, LayerData, APILegend } from '../../types';
import {
  getBaselayers,
  getLegendMinMaxSelection,
  getNrtMapsSelection,
} from '../../selectors';
import { useMapPosition } from '../../hooks';
import {
  setLegendMinMax,
  setNrtMapsSelection,
} from '../../redux/selection/actions';
import { getLeafletEventData, MapEventData } from '../../utils/leaflet.utils';
import { formatMetricName } from '../../utils/format.utils';
import * as stationService from './../../services/station.service';
import * as mapService from '../../services/map.service';
import * as metricService from '../../services/metric.service';
import { TimeframeControl } from '../../components/timeframe-control';
import { StationMarker } from '../../components/station-marker';
import { TimeseriesChart } from '../../components/timeseries-chart';
import Icon from '../../components/icon';

import {
  RasterCellPopup,
  MetricRaster,
} from '../../components/raster-cell-popup';
import { LayerCreatedAt } from '../../components/layer-created-at/layer-created-at';
import {
  SelectionGroup,
  SelectionGroupLabel,
  SelectionGroupItem,
} from '../../components/selection-group';
import { LegendContainer } from '../legend.container';
import styles from './styles.module.scss';
import { getLegend, getLegendSldUrl } from '../../services/legend.service';
import { getSelectValue } from '../../utils/form.utils';

export const NrtMapsContainer = (props: {}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector(getNrtMapsSelection);

  // select state from redux
  const { position, setMapPosition, setMapZoom } = useMapPosition();
  const baselayers = useSelector(getBaselayers);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const selectionState = useSelector(getNrtMapsSelection);
  const legendMinMaxSelection = useSelector(getLegendMinMaxSelection);

  const [stations, setStations] = useState<Station[]>([]);
  const [layers, setLayers] = useState<LayerData[]>([]);
  const [legend, setLegend] = useState<APILegend | null>(null);
  // const [sldBody, setSldBody] = useState<string | null>(null);
  const [currentMetricLayers, setCurrentMetricLayers] = useState<LayerData[]>(
    []
  );
  const [activeTimestamp, setActiveTimestamp] = useState<number | null>(null);
  const [activeStation, setActiveStation] = useState<Station | null>(null);
  const showChart = useBoolean(false);
  const [
    rasterCellMapEventData,
    setRasterCellMapEventData,
  ] = useState<MapEventData | null>(null);

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );

  const activeLayer = useMemo(() => {
    if (!layers || !activeTimestamp) return null;
    const layer = find(layers, { mapDate: activeTimestamp });
    return layer || null;
  }, [layers, activeTimestamp]);

  const legendMinMax = useMemo(() => {
    if (legendMinMaxSelection) return legendMinMaxSelection;
    if (activeMetric) return pick(activeMetric, ['min', 'max']);

    // return default legend
    console.warn('Can not define legend min/max, fallback to default.');
    return { min: 0, max: 50 };
  }, [legendMinMaxSelection, activeMetric]);

  // update legend based on active layer and min/max selection
  useEffect(() => {
    if (!activeLayer?.layerName) return;

    const { min, max } = legendMinMax;
    getLegend(activeLayer.layerName, min, max).then((legend) => {
      setLegend(legend);
    });
  }, [activeLayer, legendMinMax]);

  const metricRasterValues = useMemo(() => {
    return currentMetricLayers.reduce(
      (metricRastersAcc: MetricRaster[], layer: LayerData) => {
        const metric = find(metrics, { id: layer.metricId });
        if (!metric || !layer.wmsLayer) return metricRastersAcc;
        // else
        const value = {
          metric,
          wmsLayer: layer.wmsLayer,
        };
        return [...metricRastersAcc, value];
      },
      []
    );
  }, [currentMetricLayers, metrics]);

  /**
   * Effects
   * */
  // fetch initial data
  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setMetrics(initialData.metrics);
      setStations(initialData.stations);
      if (!selectionState.metricId) handleSetMetric(initialData.metrics[0]);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch maps based on active metric
  useEffect(() => {
    if (activeMetric) {
      fetchMapsForMetric(activeMetric);
    }
  }, [activeMetric]);

  // fetch metric nrt maps for active timestamp
  useEffect(() => {
    if (!activeTimestamp) return;

    // if active timestamp, fetch all nrt maps for this stamp
    mapService
      .getNrtMapsForTimestamp(activeTimestamp)
      .then((layers) => setCurrentMetricLayers(layers));
  }, [activeTimestamp]);

  /**
   *
   * Component functions
   */

  function handleShowTimeseries(station: Station) {
    setActiveStation(station);
    showChart.setTrue();
  }

  function handleMapClick(event: LeafletMouseEvent) {
    const mapEventData = getLeafletEventData(event);
    setRasterCellMapEventData(mapEventData);
  }

  function handleSetMetric(metric: Metric) {
    if (metric) {
      dispatch(setNrtMapsSelection({ ...selectionState, metricId: metric.id }));
      dispatch(setLegendMinMax(null));
    }
  }

  // render the component
  return render();

  function render() {
    return (
      <>
        <div className={styles.mapContainer}>
          <Map
            zoom={position.zoom}
            lat={position.lat}
            lng={position.lng}
            onChange={setMapPosition}
            onClick={handleMapClick}
          >
            {baselayers.map((layer) => (
              <TileLayer key={layer.key} url={layer.url} attribution="" />
            ))}

            {activeLayer && legendMinMax && (
              <WMSTileLayer
                {...activeLayer.wmsLayer!}
                styles="jet-dynamic"
                sld={getLegendSldUrl(legendMinMax, activeLayer)}
              />
            )}

            {renderStations()}

            {renderRasterCellPopup()}

            <MapControlGroup position="BOTTOM_RIGHT">
              <MapControlButton onClick={() => setMapZoom('in')}>
                <Icon name="plus" />
              </MapControlButton>
              <MapControlButton onClick={() => setMapZoom('out')}>
                <Icon name="minus" />
              </MapControlButton>
            </MapControlGroup>
            {activeLayer && <LayerCreatedAt layer={activeLayer} />}
          </Map>

          <MapControlGroup position="TOP_LEFT">
            <MapControl>
              <SelectionGroup>{renderMetricSelect()}</SelectionGroup>
            </MapControl>
          </MapControlGroup>

          <MapControlGroup
            position="BOTTOM_LEFT"
            css={css`
              width: 100%;
              align-items: flex-start;
            `}
          >
            <div className={styles.mapControlGroupRow}>
              {renderLegend()}
              {renderTimeseriesChart()}
            </div>
          </MapControlGroup>
        </div>
        <TimeframeControl
          layers={layers}
          activeTimestamp={activeTimestamp}
          onClick={setActiveTimestamp}
        />
      </>
    );
  }

  function renderMetricSelect() {
    const options = metrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      const metric = find(metrics, { id: metricId });
      if (metric) handleSetMetric(metric);
    }

    return (
      <SelectionGroupItem>
        <SelectionGroupLabel>{t('metric')}</SelectionGroupLabel>

        <Select
          className="select"
          value={find(options, { value: activeMetric?.id })}
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </SelectionGroupItem>
    );
  }

  function renderLegend() {
    if (!activeMetric) return null;

    return (
      <LegendContainer
        customMinMax={legendMinMaxSelection}
        metric={activeMetric}
        layer={activeLayer}
        bounds={null}
        legend={legend}
      />
    );
  }

  function renderStations() {
    if (isEmpty(stations)) return null;
    if (!activeMetric) return null;
    if (!legend) return null;

    return stations.map((station) => {
      return (
        <StationMarker
          key={station.id}
          station={station}
          legend={legend}
          activeTime={activeTimestamp || -1}
          activeMetric={activeMetric}
          onShowTimeseries={() => handleShowTimeseries(station)}
        />
      );
    });
  }

  function renderTimeseriesChart() {
    if (!showChart.value) return null;
    if (!activeStation) return null;

    function close() {
      showChart.setFalse();
    }

    return (
      <MapControl className={styles.timeseriesChartContainer}>
        <TimeseriesChart station={activeStation} onClose={close} />
      </MapControl>
    );
  }

  function renderRasterCellPopup() {
    if (!rasterCellMapEventData) return null;
    if (!legend) return null;
    const { latlng } = rasterCellMapEventData;

    return (
      <RasterCellPopup
        key={latlng.toString()}
        metricRasters={metricRasterValues}
        mapEventData={rasterCellMapEventData}
        legend={legend}
      />
    );
  }

  function fetchMapsForMetric(metric: Metric | null) {
    if (metric === null) return;

    // initially reset layers
    setLayers([]);

    mapService
      .getNRTMaps(metric.id)
      .then((maps) => {
        setLayers(maps);
        const lastLayer = last(maps);
        if (lastLayer) setActiveTimestamp(lastLayer.mapDate);
      })
      .catch((ex) => {
        setLayers([]);
      });
  }

  // TODO: rethink this with SWR (or react-query)
  async function fetchInitialData(): Promise<InitialData> {
    const metrics = await metricService.getNrtMetrics();
    const stations = await stationService.getNRTStations();

    return { metrics, stations };
  }
};

interface InitialData {
  metrics: Metric[];
  stations: Station[];
}
