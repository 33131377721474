import React, { useMemo } from 'react';
import { filter, find } from 'lodash-es';
import { CircleMarker } from 'react-leaflet';
import { Station, Metric, APILegend } from '../../types';
import { interpolateColor } from '../../utils/legend.utils';
import { StationPopup } from '../station-popup';

interface Props {
  station: Station;
  legend: APILegend;
  activeTime: number;
  activeMetric: Metric;
  onShowTimeseries: () => void;
}

export const StationMarker = (props: Props) => {
  const { station, legend, activeTime, activeMetric } = props;

  const values = useMemo(() => {
    return filter(station.values, value => {
      return (
        activeTime >= value.datetimeStart && activeTime < value.datetimeEnd
      );
    });
  }, [station, activeTime]);

  const metricValue = find(values, value => {
    return value.metricId === activeMetric.id;
  });

  const colorInterpolator = (legend: APILegend) => (value: number) =>
    interpolateColor(legend, value);

  if (!metricValue) return null;

  return (
    <CircleMarker
      key={station.id}
      radius={5}
      color="black"
      opacity={1}
      weight={1}
      fillColor={colorInterpolator(legend)(metricValue.value)}
      fillOpacity={0.8}
      center={[station.lat, station.lon]}
    >
      <StationPopup
        station={station}
        values={values}
        colorInterpolator={colorInterpolator(legend)}
        onShowTimeseries={props.onShowTimeseries}
      />
    </CircleMarker>
  );
};
