import { BasedataState, BasedataActionTypes, SET_METRICS } from './types';

export const initialState: BasedataState = {
  metrics: [],
};

export const basedataReducer = (
  state = initialState,
  action: BasedataActionTypes
): BasedataState => {
  switch (action.type) {
    case SET_METRICS:
      return { ...state, metrics: action.payload };

    default:
      return state;
  }
};
