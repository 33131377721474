import { Machine } from 'xstate';

interface Context {
  retries: number;
}

export const resetPasswordMachine = Machine({
  id: 'resetPassword',
  initial: 'idle',
  context: {
    retries: 0,
  },
  states: {
    idle: {
      on: {
        SUBMIT: 'loading',
      },
    },
    loading: {
      entry: ['load'],
      on: {
        RESOLVE: 'success',
        REJECT: 'failure',
      },
    },
    success: {
      type: 'final',
    },
    failure: {
      on: {
        RETRY: 'loading',
        SUBMIT: 'loading',
      },
    },
  },
});
