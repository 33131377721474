import styled, { css } from 'styled-components';
import variables from './../styles/core/variables.scss';

export const RadioList = styled.div`
  border: 1px solid #dddddd;
  background-color: white;
  padding: 15px;
`;

export const RadioListItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: white;
  font-family: ${variables.fontFamilyAvenirRoman};
  font-size: 12px;
  font-style: normal;
  color: #000;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    background: white;
    box-sizing: border-box;
    border: 1px solid #bbbbbb;
    border-radius: 100%;
    margin-right: 10px;

    ${props =>
      props.active &&
      css`
        width: 17px;
        height: 17px;
        border: 5px solid #33a3dc;
      `}
  }

  & + & {
    margin-top: 15px;
  }

  ${props =>
    props.active &&
    css`
      font-family: ${variables.fontFamilyAvenirHeavy};
    `}
`;
