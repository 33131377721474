import React, { useMemo } from 'react';
import { PlotData } from 'plotly.js';
import Plot from 'react-plotly.js';
import { DashboardOverviewData } from '../services/dashboard.service';
import { parseModels } from '../utils/data.utils';
import { useTranslation } from 'react-i18next';

interface Props {
  data: DashboardOverviewData[];
}

export const DashboardForecastGraph = (props: Props) => {
  const { t } = useTranslation();
  const { data } = props;

  const models = useMemo(() => parseModels(data), [data]);

  const traces = useMemo(() => {
    // define a trace per model
    const traces = models.map(model => {
      const modelData = data.filter(item => item.modelId === model.id);
      const plotData: Partial<PlotData> = {
        x: modelData.map(d => {
          return t('dashboard.overview.dayPlus', { horizon: d.horizon }) as string;
        }),
        y: modelData.map(d => d.value),
        type: 'scatter',
        mode: 'lines+markers',
        name: model.name,
        marker: { color: model.color },
      };

      return plotData;
    });

    return traces;
  }, [t, data, models]);

  // render the component
  return render();

  function render() {
    return (
      <Plot
        style={{ width: '100%', height: '100%' }}
        data={traces}
        layout={{
          autosize: true,
          showlegend: true,
          legend: { orientation: 'h', y: -0.25 },
          margin: { l: 0, r: 0, t: 40, b: 0 },
          xaxis: {
            zeroline: false,
            tickmode: 'linear',
            automargin: true,
          },
          yaxis: {
            zeroline: false,
            tickformat: '.1f',
            automargin: true,
          },
        }}
      />
    );
  }
};
