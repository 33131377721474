import React, { useMemo } from 'react';
import { uniq, xor, noop } from 'lodash-es';

import { DashboardValidationData } from '../services/dashboard.service';
import { Model } from '../types';
import { parseModels } from '../utils/data.utils';
import { Table, TableRow, TableHead, TableCell } from '../components/table';
import { ModelCell } from '../components/model-cell';
import { CheckBox } from 'marvin-ui-kit';
import styled from 'styled-components';

interface OwnProps {
  data: DashboardValidationData;
  activeModelIds: number[];
  onActiveModelsChange: (models: number[]) => void;
}

type Props = OwnProps & React.HTMLAttributes<HTMLDivElement>;

export const DashboardValidationTable = (props: Props) => {
  const { data, activeModelIds } = props;

  const models = useMemo<Model[]>(() => {
    return parseModels(data.values);
  }, [data.values]);

  const statisticKeys = useMemo(() => {
    return data.statistics.reduce(
      (
        statisticsAcc: string[],
        stat: DashboardValidationData['statistics'][0]
      ) => {
        const statKeys = stat.values.map(value => value.key);
        return uniq([...statisticsAcc, ...statKeys]);
      },
      []
    );
  }, [data.statistics]);

  return render();

  function render() {
    return (
      <Table className={props.className}>
        <thead>{renderTableHeader()}</thead>
        <tbody>{models.map(renderTableRow)}</tbody>
      </Table>
    );
  }

  function renderTableHeader() {
    return (
      <TableRow>
        <TableHead>Model</TableHead>
        {statisticKeys.map(statKey => (
          <TableHead key={statKey}>{statKey}</TableHead>
        ))}
      </TableRow>
    );
  }

  function renderTableRow(model: Model) {
    const statistic = data.statistics.find(item => model.id === item.modelId);
    if (!statistic) return null;

    const modelStatus = activeModelIds.includes(model.id)
      ? 'checked'
      : 'unchecked';

    function onClickCheckbox() {
      props.onActiveModelsChange(xor(activeModelIds, [model.id]));
    }

    return (
      <TableRow key={model.id}>
        <TableCellModel>
          <div onClick={onClickCheckbox}>
            <CheckBox status={modelStatus} />
            <ModelCell color={model.color}>{model.name}</ModelCell>
          </div>
        </TableCellModel>

        {statisticKeys.map((statKey, i) => {
          const statValue = statistic.values.find(v => v.key === statKey);
          return (
            <TableCell key={model.id + statKey + i}>
              {statValue?.value || '-'}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
};

DashboardValidationTable.defaultProps = {
  activeModelIds: [],
  onActiveModelsChange: noop,
};

const TableCellModel = styled(TableCell)`
  padding-left: 0;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
`;
