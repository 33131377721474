import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import cx from 'classnames';
import { auth } from '../../services/auth.service';
import { ServerError } from '../../types';
import { setAuthTokens } from '../../redux/auth/actions';
import { Button, ButtonSecondary } from '../../components/button';
import {
  InvalidFeedback,
  FormGroup,
  Label,
  InputField,
} from '../../components/form';
import { H1, H2 } from '../../components/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

export const LoginContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const [serverErrors, setServerErrors] = useState<ServerError[]>([]);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(t('login.form.usernameRequired')),
    password: Yup.string().required(t('login.form.passwordRequired')),
  });

  // functions
  function redirectAfterSuccess() {
    if (location.state && location.state.from.pathname !== '/login') {
      history.push(location.state.from.pathname);
    } else {
      history.push('/');
    }
  }

  return render();

  function render() {
    return (
      <div className={styles.container}>
        <H1>{t('login.title')}</H1>
        <H2>{t('login.subtitle')}</H2>

        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={async (values, actions) => {
            setServerErrors([]);

            try {
              const token = await auth.login(values.username, values.password);
              console.log(token);

              dispatch(setAuthTokens(token, token));
              redirectAfterSuccess();
            } catch (err) {
              // setServerErrors(err.response.data);
              console.error(
                'Actually, login request failed, but until auth service is finished, we do nothing special with failure'
              );
              redirectAfterSuccess();
            }
            actions.setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting, handleSubmit, getFieldProps }) => (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <FormGroup>
                <Label htmlFor="username">{t('login.form.username')}</Label>
                <InputField
                  {...getFieldProps('username')}
                  id="username"
                  placeholder={t('login.form.usernamePlaceholder')}
                />
                <InvalidFeedback>
                  <ErrorMessage name="username" />
                </InvalidFeedback>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">{t('login.form.password')}</Label>
                <InputField
                  {...getFieldProps('password')}
                  id="password"
                  placeholder={t('login.form.passwordPlaceholder')}
                  type="password"
                />
                <InvalidFeedback>
                  <ErrorMessage name="password" />
                </InvalidFeedback>
              </FormGroup>

              {serverErrors.length > 0 && (
                <div className={styles.formGroup}>
                  <div className={cx(styles.alert, styles.alertDanger)}>
                    {serverErrors.length > 0 &&
                      serverErrors.map(error => {
                        if (error.key === 'invalid_credentials')
                          return t('login.errors.invalidCredentials');

                        return error.message;
                      })}
                  </div>
                </div>
              )}

              <FormGroup className={styles.formActions}>
                <Button type="submit" disabled={isSubmitting}>
                  {t('login.actions.login')}
                </Button>
                <ButtonSecondary to="/forgot-password" as={Link}>
                  {t('login.actions.forgotPassword')}
                </ButtonSecondary>
              </FormGroup>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};
