import React, { useState, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { AnimationImage, Metric, APILegend } from '../../types';
import { LegendIntervals } from 'marvin-ui-kit';
import { formatDate } from '../../utils/format.utils';

interface Props {
  legend: APILegend;
  metric: Metric;
  images: AnimationImage[];
  activeIndex: number;
}

export const AnimationStrip = (props: Props) => {
  const { activeIndex, images, metric } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // prefetch all images
    const loadPromises = images.map((img) => {
      return new Promise((resolve) => {
        const obj = new Image();
        obj.src = img.url;
        obj.onload = resolve;
      });
    });

    Promise.all(loadPromises).then(() => setIsLoading(false));
  }, [images]);

  const activeImage = useMemo(() => {
    return images[activeIndex];
  }, [activeIndex, images]);

  // render the component
  return render();

  function render() {
    if (!activeImage) return null;

    return (
      <div className={styles.container}>
        {isLoading && <span>Loading ...</span>}
        {!isLoading && (
          <>
            <div className={styles.activeFrame}>
              <div className={styles.activeFrameInfo}>
                <div>
                  <span className={styles.quantity}>
                    {metric.quantityDescription}
                  </span>{' '}
                  <span className={styles.unit}>{metric.unit}</span>
                </div>
                <div>
                  <span className={styles.time}>
                    {formatDate(
                      activeImage.mapDate * 1000,
                      'dd/MM/yyyy - kk:mm'
                    )}
                  </span>
                </div>
              </div>
              <div className={styles.activeFrameImage}>
                <img src={activeImage.url} alt="emission frame" />
              </div>
            </div>
            <div className={styles.legend}>
              <LegendIntervals colorMap={props.legend.colorMap} />
            </div>
          </>
        )}
      </div>
    );
  }
};
