import React, { useRef, useEffect } from 'react';
import * as L from 'leaflet';

export const LeafletDisableClickPropagation = (props: {
  children: React.ReactNode;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      L.DomEvent.disableClickPropagation(containerRef.current);
    }
  }, []);

  return <div ref={containerRef}>{props.children}</div>;
};
