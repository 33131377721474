import React, { useMemo } from 'react';
import { PlotData } from 'plotly.js';
import Plot from 'react-plotly.js';

import { DashboardValidationData } from '../services/dashboard.service';
import { parseModels } from '../utils/data.utils';

interface Props {
  activeModelIds: number[];
  data: DashboardValidationData;
}
export const DashboardValidationDailyChart = (props: Props) => {
  const { activeModelIds, data } = props;

  const models = useMemo(() => {
    return parseModels(data.values).filter(model =>
      activeModelIds.includes(model.id)
    );
  }, [activeModelIds, data.values]);

  const traces = useMemo(() => {
    // define a trace per model
    const traces = models.map(model => {
      const dataForModel = data.values.filter(
        item => item.modelId === model.id
      );
      const plotData: Partial<PlotData> = {
        x: dataForModel.map(d => new Date(d.fctimeStart * 1000)),
        y: dataForModel.map(d => {
          // return d.value;
          if (d.value !== d.missingValue) return d.value;
          return null;
        }),
        name: model.name,
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: model.color },
      };

      return plotData;
    });

    return traces;
  }, [data.values, models]);

  // render the component
  return render();

  function render() {
    return (
      <Plot
        style={{ width: '100%', height: '100%' }}
        data={traces}
        layout={{
          autosize: true,
          legend: { orientation: 'h', y: -0.25 },
          margin: { l: 0, r: 0, t: 40, b: 0 },
          xaxis: { automargin: true, zeroline: false },
          yaxis: { automargin: true, zeroline: false, tickformat: '.1f' },
        }}
      />
    );
  }
};
