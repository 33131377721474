import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import cx from 'classnames';
import { useMachine } from '@xstate/react';

import * as authService from '../../services/auth.service';
import { resetPasswordMachine } from '../../state-machines/reset-password.machine';
import { ServerError } from '../../types';
import { Button, ButtonSecondary } from '../../components/button';
import {
  InvalidFeedback,
  FormGroup,
  Label,
  InputField,
} from '../../components/form';
import { H1 } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../hooks';
import styles from './styles.module.scss';

interface Props {}

export const ResetPasswordContainer = (props: Props) => {
  const { t } = useTranslation();
  const query = useQuery();
  const [serverErrors, setServerErrors] = useState<ServerError[]>([]);
  const [current, send] = useMachine(resetPasswordMachine, {
    actions: {
      load: async (context, event) => {
        setServerErrors([]);
        const token = query.token;

        try {
          await authService.resetPassword(token, event.payload.values.password);
          send('RESOLVE');
        } catch (err) {
          send('REJECT');
        }
      },
    },
  });

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(t('resetPassword.form.passwordRequired')),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], t('resetPassword.form.passwordMatch'))
      .required(t('resetPassword.form.passwordConfirmRequired')),
  });

  return render();

  function render() {
    if (!query.token) return renderInvalid();
    if (current.matches('success')) return renderSuccess();
    return renderIdle();
  }

  function renderInvalid() {
    console.warn(
      'Can not reset password without a valid token. Please ensure the link has a token'
    );
    return (
      <div className={styles.container}>
        <H1>Something is wrong.</H1>
        <p>
          Can not reset password without a valid token. Please ensure the link
          has a valid token included.
        </p>
      </div>
    );
  }

  function renderIdle() {
    return (
      <div className={styles.container}>
        <H1>{t('resetPassword.title.idle')}</H1>

        <Formik
          initialValues={{ password: 'a', passwordConfirm: 'a' }}
          validationSchema={LoginSchema}
          onSubmit={async values => {
            send({ type: 'SUBMIT', payload: { values } });
          }}
        >
          {({ handleSubmit, getFieldProps }) => (
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <FormGroup>
                <Label htmlFor="password">
                  {t('resetPassword.form.password')}
                </Label>
                <InputField
                  {...getFieldProps('password')}
                  id="password"
                  placeholder={t('resetPassword.form.passwordPlaceholder')}
                  type="password"
                />
                <InvalidFeedback>
                  <ErrorMessage name="password" />
                </InvalidFeedback>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="passwordConfirm">
                  {t('resetPassword.form.passwordConfirm')}
                </Label>
                <InputField
                  {...getFieldProps('passwordConfirm')}
                  id="passwordConfirm"
                  placeholder={t(
                    'resetPassword.form.passwordConfirmPlaceholder'
                  )}
                  type="password"
                />
                <InvalidFeedback>
                  <ErrorMessage name="passwordConfirm" />
                </InvalidFeedback>
              </FormGroup>

              {serverErrors.length > 0 && (
                <div className={styles.formGroup}>
                  <div className={cx(styles.alert, styles.alertDanger)}>
                    {serverErrors.length > 0 &&
                      serverErrors.map(error => {
                        // TODO: catch invalid token error
                        // if (error.key === 'invalid_credentials')
                        //   return t('resetPassword.errors.invalidCredentials');

                        return error.message;
                      })}
                  </div>
                </div>
              )}

              <InvalidFeedback>
                {current.matches('failure') && (
                  <div>
                    {t('resetPassword.form.failure')}
                    <div className="link" onClick={() => handleSubmit()}>
                      {t('resetPassword.actions.retry')}
                    </div>
                  </div>
                )}
              </InvalidFeedback>

              <FormGroup className={styles.formActions}>
                <Button type="submit" disabled={current.matches('loading')}>
                  {current.matches('loading')
                    ? 'loading'
                    : t('resetPassword.actions.submit')}
                </Button>
                <ButtonSecondary to="/login" as={Link}>
                  {t('resetPassword.actions.cancel')}
                </ButtonSecondary>
              </FormGroup>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  function renderSuccess() {
    return (
      <div className={styles.container}>
        <H1>{t('resetPassword.title.success')}</H1>
        <p>{t('resetPassword.body.success')}</p>

        <FormGroup className={styles.formActions}>
          <Button to="/login" as={Link}>
            Login
          </Button>
        </FormGroup>
      </div>
    );
  }
};
