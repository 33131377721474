import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getForecastDashboardOverviewSelection } from './../../../selectors';
import {
  ForecastDashboardOverviewForm,
  DashboardOverviewFormData,
} from './forecast-dashboard-overview-form';
import {
  DashboardOverviewData,
  getDashboardOverviewData,
} from '../../../services/dashboard.service';
import styles from './styles.module.scss';
import { H3 } from '../../../components/typography';
import { DashboardOverviewTable } from '../../../components/dashboard-overview-table';
import { DashboardForecastGraph } from '../../../components/dashboard-forecast-graph';
import { setForecastDashboardOverviewSelection } from '../../../redux/selection/actions';
import { getForecastMetrics } from '../../../services/metric.service';
import { Metric } from '../../../types';

interface Props {}

export function ForecastDashboardOverview(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formData = useSelector(getForecastDashboardOverviewSelection);
  const [data, setData] = useState<DashboardOverviewData[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);

  const activeMetric = useMemo(
    () => metrics.find((metric) => metric.id === formData.metricId),
    [metrics, formData.metricId]
  );

  // fetch initial data
  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setMetrics(initialData.metrics);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // populate view with initial data
  useEffect(() => {
    const { station, metricId, basetime } = formData;
    if (station && metricId && basetime) {
      getDashboardOverviewData(station.id, metricId, basetime).then(setData);
    }
  }, [formData]);

  async function handleFormSubmit(formData: DashboardOverviewFormData) {
    dispatch(setForecastDashboardOverviewSelection(formData));
    const data = await getDashboardOverviewData(
      formData.station.id,
      formData.metricId,
      formData.basetime
    );
    setData(data);
  }

  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <ForecastDashboardOverviewForm onSubmit={handleFormSubmit} />
        </div>

        <div className={styles.main}>
          <div className={styles.tableContainer}>
            <H3>
              {t('dashboard.overview.table.title', {
                metric: activeMetric?.quantityName,
                unit: activeMetric?.unit,
              })}
            </H3>
            <DashboardOverviewTable data={data} />
          </div>

          <div className={styles.graphContainer}>
            <H3>
              {t('dashboard.overview.graph.title', {
                metric: activeMetric?.quantityName,
                unit: activeMetric?.unit,
              })}
            </H3>

            <DashboardForecastGraph data={data} />
          </div>
        </div>
      </div>
    );
  }
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const metrics = await getForecastMetrics();

  return { metrics };
}

interface InitialData {
  metrics: Metric[];
}
