import React from 'react';
import { Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { StationValue, Station } from '../../types';
import { MetricValueItem } from '../metric-value-item';
import styles from './styles.module.scss';
import { MetricMeasurementItems } from '../metric-measurement-items';

interface Props {
  station: Station;
  values: StationValue[];
  colorInterpolator: (value: number) => string;
  onShowTimeseries: () => void;
}

export const StationPopup = React.memo((props: Props) => {
  const { t } = useTranslation();
  const { station, values, colorInterpolator } = props;

  return (
    <Popup autoPan={false}>
      <MetricMeasurementItems
        header={`${t('stationPopup.station')}: ${station.localCode}`}
        measurements={values.map(value => (
          <MetricValueItem
            key={value.id}
            value={value.value}
            unit={value.unit}
            quantityName={value.quantityName}
            colorInterpolator={colorInterpolator}
          />
        ))}
        actions={
          <button
            className={styles.buttonDefault}
            onClick={() => props.onShowTimeseries()}
          >
            {t('actions.viewTimeseries')}
          </button>
        }
      />
    </Popup>
  );
});
