import { uniqBy } from 'lodash-es';
import { DashboardOverviewData } from '../services/dashboard.service';

export function parseModels(data: DashboardOverviewData[]) {
  return uniqBy(data, 'modelId').map((item) => ({
    id: item.modelId,
    name: item.modelName,
    color: item.modelColor,
  }));
}

interface ValueMetric {
  id: number;
  name: string;
}
export function parseMetrics(
  data: Array<{ metricId: number; quantityName: string }>
): ValueMetric[] {
  return uniqBy(
    data.map((value) => ({
      id: value.metricId,
      name: value.quantityName,
    })),
    'id'
  );
}
