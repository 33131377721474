import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isNumber } from 'lodash-es';

import { getPosition } from '../selectors';
import { MapPosition } from '../types';
import * as MapActions from '../redux/map/actions';

export function useMapPosition() {
  const dispatch = useDispatch();
  const position = useSelector(getPosition);

  function setMapPosition(newPosition: MapPosition) {
    if (!isEqual(position, newPosition)) {
      dispatch(MapActions.setMapPosition(newPosition));
    }
  }

  function setMapZoom(direction: 'in' | 'out' | number) {
    const { zoom } = position;
    if (isNumber(direction)) {
      dispatch(MapActions.setMapPosition({ ...position, zoom: direction }));
    }
    if (direction === 'in') {
      dispatch(MapActions.setMapPosition({ ...position, zoom: zoom + 1 }));
    }
    if (direction === 'out') {
      if (zoom === 0) return;
      dispatch(MapActions.setMapPosition({ ...position, zoom: zoom - 1 }));
    }
  }

  return { position, setMapPosition, setMapZoom };
}
