import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouterProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'marvin-ui-kit';

import atmosysLogo from './../../assets/images/logo-atmosys.png';
import Icon from '../icon';

import styles from './main-navigation.module.scss';

interface OwnProps {}

type Props = OwnProps | RouterProps;

export const MainNavigation = (props: Props) => {
  const { NODE_ENV } = process.env;
  const { t } = useTranslation();

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.applicationLogo}>
          <img src={atmosysLogo} alt="Atmosys logo" />
        </div>

        <div className={styles.navGroup}>
          <div className={styles.navTitle}>
            {t('navigation.title.nearRealTime')}
          </div>

          <NavLink
            to="/nrt/maps"
            className={styles.navItem}
            activeClassName={styles.isActive}
          >
            <Icon name="clock" className={styles.icon} size={13} />
            {t('navigation.menu.nrtMaps')}
          </NavLink>

          <NavLink
            to="/nrt/animation"
            className={styles.navItem}
            activeClassName={styles.isActive}
          >
            <Icon name="film" className={styles.icon} size={15} />
            {t('navigation.menu.nrtAnimation')}
          </NavLink>
        </div>

        <div className={styles.navGroup}>
          <div className={styles.navTitle}>
            {t('navigation.title.airQualityForecast')}
          </div>

          <NavLink
            to="/forecast/maps"
            className={styles.navItem}
            activeClassName={styles.isActive}
          >
            <Icon name="calendar-check" className={styles.icon} size={13} />
            {t('navigation.menu.forecastMaps')}
          </NavLink>

          <NavLink
            to="/forecast/dashboard"
            className={styles.navItem}
            activeClassName={styles.isActive}
          >
            <Icon name="chart-pie" className={styles.icon} size={15} />
            {t('navigation.menu.forecastDashboard')}
          </NavLink>
        </div>

        <div className={styles.navGroup}>
          <div className={styles.navTitle}>
            {t('navigation.title.historicMaps')}
          </div>

          <NavLink
            to="/historical/maps"
            className={styles.navItem}
            activeClassName={styles.isActive}
          >
            <Icon name="map" className={styles.icon} size={15} />
            {t('navigation.menu.historicMaps')}
          </NavLink>
        </div>

        {renderBuildDetails()}
      </div>
    );
  }

  function renderBuildDetails() {
    function getDetails() {
      if (NODE_ENV === 'development') {
        return 'Build id: development';
      } else if (NODE_ENV === 'production') {
        return `Build id: ${window._env_.REACT_APP_BUILD_ID} \n Git commit: ${window._env_.REACT_APP_GIT_COMMIT}`;
      }
      return '';
    }

    return (
      <div className={styles.appDetails}>
        <Tooltip text={getDetails()} position="right">
          <Icon
            className={styles.icon}
            name="code-branch"
            size={15}
            fill="#ccc"
          />
        </Tooltip>
      </div>
    );
  }
};
