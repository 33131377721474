import { MapPosition } from '../../types';

export const SET_MAP_POSITION = 'SET_MAP_POSITION';

export interface MapState {
  position: MapPosition;
}

interface SetMapPositionAction {
  type: typeof SET_MAP_POSITION;
  payload: MapPosition;
}
export type MapActionTypes = SetMapPositionAction;
