import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

export function useQuery() {
  const params = useParams();
  const location = useLocation();

  return {
    ...queryString.parse(location.search), // Convert string to object
    ...params,
  };
}
