import React from 'react';
import styles from './styles.module.scss';

export const MetricMeasurementItems = (props: {
  header: string;
  measurements: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return render();
  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>{props.header}</div>
        <div className={styles.content}>
          <div className={styles.measurements}>{props.measurements}</div>
          {props.actions}
        </div>
      </div>
    );
  }
};
