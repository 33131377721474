import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LayerData, CrossSection } from '../../types';
import * as DataAnalysisService from './../../services/data-analysis.service';
import { Drawing } from '../../components/draw-control/draw-control';
import { getDrawing } from '../../selectors';
import { CrossSectionComponent } from '../../components/cross-section-plotly';
import styles from './styles.module.scss';

interface Props {
  layers: LayerData[];
  closeGraph: () => void;
}

export const GraphContainer = (props: Props) => {
  const drawing = useSelector(getDrawing);
  const [data, setData] = useState<CrossSection[] | null>(null);

  const { layers } = props;

  const fetchCrossSectionData = useCallback(
    async (drawing: Drawing) => {
      const result: CrossSection[] = await DataAnalysisService.getCrossSectionData(
        layers,
        drawing
      );
      setData(result);
    },
    [layers]
  );

  // TODO: this effect could be optimised by checking if a layer is removed (this means no extra data should be fetched)
  useEffect(() => {
    if (!drawing) return;
    fetchCrossSectionData(drawing);
  }, [layers, drawing, fetchCrossSectionData]);

  // render the component
  return render();

  function render() {
    if (data && drawing) {
      return (
        <CrossSectionComponent
          className={styles.graph}
          crossSections={data}
          closeGraph={props.closeGraph}
        />
      );
    }

    // else
    return null;
  }
};
