import styled from 'styled-components';
import variables from './../styles/core/variables.scss';

export const Table = styled.table`
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  height: 40px;
`;

export const TableHead = styled.th`
  color: #3fabff;
  font-family: serif ${variables.fontFamilyAvenirLight};
  padding: 0 20px;
  background-color: #f9f9f9;
  border-right: 1px solid rgb(112, 112, 112, 0.3);
  border-bottom: 1px solid rgb(112, 112, 112, 0.3);

  &:last-child {
    border-right: none;
  }
`;
export const TableCell = styled.td`
  border-right: 1px solid rgb(112, 112, 112, 0.3);
  padding: 0 20px;
  &:last-child {
    border-right: none;
  }
`;
