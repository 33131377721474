import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getYear, isSameDay } from 'date-fns/esm';
import DayPicker from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Station, Metric } from '../../../types';
import { getForecastDashboardOverviewSelection } from '../../../selectors';
import { getForecastStations } from '../../../services/station.service';
import { formatMetricName } from '../../../utils/format.utils';
import { getForecastDashboardMetrics } from '../../../services/metric.service';
import { Button } from '../../../components/button';
import { getDashboardDates } from '../../../services/dashboard.service';
import { Label, FormGroup } from '../../../components/form';
import styles from './styles.module.scss';
import { localeUtils } from '../../../utils/react-day-picker.utils';
import { find } from 'lodash-es';
import { getSelectValue } from '../../../utils/form.utils';

export interface DashboardOverviewFormData {
  station: Station;
  metricId: number;
  basetime: Date;
}

interface Props {
  onSubmit: (data: DashboardOverviewFormData) => void;
}

export function ForecastDashboardOverviewForm(props: Props) {
  const { t, i18n } = useTranslation();

  // select redux state
  const formData = useSelector(getForecastDashboardOverviewSelection);

  // component state
  const [selectedStation, setSelectedStation] = useState(formData.station);
  const [selectedMetricId, setSelectedMetricId] = useState(formData.metricId);
  const [selectedBasetime, setSelectedBasetime] = useState(
    formData.basetime || new Date()
  );
  const [allStations, setAllStations] = useState<Station[]>([]);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [selectedYear, setSelectedYear] = useState(getYear(selectedBasetime));
  const [datesWithData, setDatesWithData] = useState<Date[]>([]);

  useEffect(() => {
    fetchInitialData().then((initialData) => {
      setAllStations(initialData.stations);
    });
  }, []);

  useEffect(() => {
    if (!selectedStation) return setMetrics([]);

    getForecastDashboardMetrics(selectedStation).then((metrics) =>
      setMetrics(metrics)
    );
  }, [selectedStation]);

  // fetch availlable dates for selected year / metric
  useEffect(() => {
    if (!selectedStation || !selectedMetricId) return;

    getDashboardDates(selectedStation.id, selectedMetricId, selectedYear).then(
      setDatesWithData
    );
  }, [selectedStation, selectedMetricId, selectedYear]);

  async function handleSubmit() {
    if (selectedStation && selectedMetricId && selectedBasetime) {
      props.onSubmit({
        station: selectedStation,
        metricId: selectedMetricId,
        basetime: selectedBasetime,
      });
    }
  }

  return render();

  function render() {
    return (
      <div className={styles.form}>
        {renderStationSelect()}
        {renderMetricSelect()}
        {renderBaseTimeSelect()}

        <div className={styles.formGroup}>
          <Button onClick={handleSubmit}>{t('actions.load')}</Button>
        </div>
      </div>
    );
  }

  function renderStationSelect() {
    const options = allStations.map((station) => ({
      value: station.id,
      label: `${station.description} - ${station.localCode}`,
    }));

    function handleChange(stationId: number) {
      const station = find(allStations, { id: stationId });
      if (station) {
        setSelectedStation(station);
      }
    }
    return (
      <FormGroup>
        <Label>{t('station')}</Label>

        <Select
          className="select"
          value={find(options, { value: selectedStation?.id })}
          placeholder={t('stationPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </FormGroup>
    );
  }

  function renderMetricSelect() {
    const options = metrics.map((metric) => ({
      value: metric.id,
      label: formatMetricName(metric),
    }));

    function handleChange(metricId: number) {
      const metric = find(metrics, { id: metricId });
      if (metric) {
        setSelectedMetricId(metric.id);
      }
    }

    return (
      <FormGroup>
        <Label>{t('metric')}</Label>
        <Select
          className="select"
          value={
            options.find((option) => option.value === selectedMetricId) || null
          }
          placeholder={t('metricPlaceholder')}
          options={options}
          onChange={(e) => handleChange(getSelectValue(e))}
        ></Select>
      </FormGroup>
    );
  }

  function renderBaseTimeSelect() {
    const modifiers = {
      withData: datesWithData,
    };

    function handleDayClick(clickedDay: Date) {
      const availableDay = datesWithData.find((date) =>
        isSameDay(date, clickedDay)
      );
      setSelectedBasetime(availableDay || clickedDay);
    }

    function handleMonthChange(month: Date) {
      const year = getYear(month);
      if (year !== selectedYear) setSelectedYear(year);
    }

    return (
      <>
        <FormGroup>
          <Label>{t('basetime')}</Label>
          <div className={styles.dateRange}>
            <DayPicker
              showOutsideDays
              modifiers={modifiers}
              initialMonth={selectedBasetime}
              selectedDays={selectedBasetime}
              onDayClick={handleDayClick}
              onMonthChange={handleMonthChange}
              locale={i18n.language}
              localeUtils={localeUtils}
            />
          </div>
        </FormGroup>
      </>
    );
  }
}

interface InitialData {
  stations: Station[];
}

// TODO: rethink this with SWR (or react-query)
async function fetchInitialData(): Promise<InitialData> {
  const stations = await getForecastStations();
  return { stations };
}
